<template>
  <div>
    <Banner :imageList="bannerImages" type="home" v-if="!!middleBanner"/>
    <ContentLoading v-else type="banner" />
    <h1 class="text-center mt-5 mb-0 mx-1" :dir="lang === 'en'?'ltr':''" v-if="hotel" style="font-weight: bold;">{{$t("hotel-page.hotel-breadcrumb-hotel-third-clone",
      {destinationName: destinationName, hotelname: hotel.hotelConversion ? hotel.hotelConversion.hotelName: ''})}}</h1>
    <div :class="{'m-3': device === 'desktop'}">
      <b-img-lazy v-bind="mainProps" thumbnail
      :src="hotel.mainImageUrls[0] || hotel.galleryImageUrls[0] || hotel.allImageUrls[0]" alt="Hotel Image" v-if="hotel"></b-img-lazy>
    </div>

    <div class="col-12 pt-5" v-if="isLoading || !hotel">
      <ContentLoading />
    </div>
    <div class="col-12 p-4" v-else>
      <!-- <ReadMore :message="hotel.hotelDescription" :line="5"/> -->
      <div ref="desc" v-html="hotel.hotelDescription"/>
      <div class="facilitie-list">
        <Facilitie v-for="(item, index) in hotel.facilities" :key="index" :data="item.name"/>
      </div>
      <div class="clearfix mb-4"></div>
      <CardGroup :items="rooms" page="rooms"/>
    </div>

    <Footer page="home" v-if="!hideCondition"/>
    <bottom-left-call />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BImgLazy } from 'bootstrap-vue';
import getBannerImage from '@/mixins/getBannerImage';

export default {
  name: 'HotelsPage',
  data() {
    return {
      mainProps: {
        center: true,
        fluidGrow: true,
        blank: true,
        blankColor: '#bbb',
        width: 600,
        height: 400,
      },
    };
  },
  metaInfo() {
    const div = document.createElement('div');
    div.innerHTML = this.hotel?.hotelDescription;
    // const description = div.innerText.slice(0, 100);
    const description = `מבצעים ודילים למלון ${this.hotelName} ${this.destinationName}. חברת השטיח המעופך, עד 30% הנחה למלון ${this.hotelName} ${this.destinationName}. כנסו לאתר עכשיו! הנחות עד 30%`;
    div.remove();

    return {
      title: `מלון ${this.hotelName} - ${this.destinationName}  | השטיח המעופף`,
      meta: [
        { name: 'description', content: description },
        { name: 'robots', content: 'noindex,nofollow' },
        { property: 'og:title', content: `מלון ${this.destinationName} ב${this.hotelName}` },
      ],
    };
  },
  mixins: [getBannerImage],
  components: {
    Banner: () => import('@/components/content/Banner'),
    Footer: () => import('@/components/content/footer/FooterTheme0'),
    BottomLeftCall: () => import('@/components/content/BottomLeftCall'),
    ContentLoading: () => import('@/components/atoms/ContentLoading'),
    CardGroup: () => import('@/components/hotelPages/CardColumnGroup'),
    // ReadMore: () => import('@/components/productPage/atoms/Readmore'),
    Facilitie: () => import('@/components/productPage/atoms/Facilitie'),
    BImgLazy,
  },
  async mounted() {
    window.localStorage.setItem('landing-url', window.location.search);
    this.$i18n.locale = 'he';
    this.$store.dispatch('UPDATE_LANGUAGE', this.$i18n.locale);
    window.localStorage.setItem('language', this.$i18n.locale);
    this.$root.$emit('callBreadcrumbEvent');
  },
  computed: {
    ...mapGetters({
      destinationList: 'GET_DOMESTIC_DESTINATION',
      hotelList: 'GET_DOMESTIC_HOTELS',
      hotelAllInfo: 'GET_HOTEL_ALL_INFO',
      isLoading: 'GET_LOADING_STATE',
      lang: 'GET_LANGUAGE',
      device: 'GET_DEVICE',
      hideCondition: 'GET_HIDE_CONDITION',
    }),
    query() {
      const route = this.$route;
      return route.query;
    },
    cityId() {
      return this.query.city;
    },
    hotelCode() {
      return this.query.hotelCode;
    },
    destinationName() {
      if (this.destinationList.length === 0) return '';
      return this.destinationList.find((elem) => elem.code === this.cityId).name;
    },
    hotel() {
      if (!this.hotelAllInfo.length || !this.hotelCode) return null;

      const hotelInfo = this.hotelAllInfo;
      const hotel = hotelInfo.find((elm) => elm.hotelConversion?.hotelExternalCode === this.hotelCode || elm.hotelConversion?.hotelAtlantisCode === this.hotelCode);
      return hotel;
    },
    rooms() {
      if (!this.hotel) return null;

      const { hotel } = this;
      if (!hotel.roomClasses) return null;
      const rooms = hotel.roomClasses.map((elm, inx) => ({
        src: elm.imageUrls || hotel.allImageUrls[inx],
        name: elm.name,
        description: elm.description,
      }));

      return rooms;
    },
    hotelName() {
      return this.hotel?.hotelConversion?.hotelName || '';
    },
  },
  watch: {
    query() {
      document.querySelector('.st-content').scrollTo(400, 400);
    },
  },
  async created() {
    this.$store.commit('SET_CURRENT_PAGE', 'hotel-info');
    if (!this.hotelAllInfo.length) {
      await this.$store.dispatch('FETCH_ALL_HOTEL_INFO');
    }
    document.querySelector('.st-content').scrollTo(400, 400);
  },
};
</script>

<style>
  .clearfix{
    clear: both;
  }
</style>
