<template>
  <div>
    <ProductPageTheme0 v-if="kindTheme === 0 || kindTheme === 2"/>
    <ProductPageTheme1 v-else-if="kindTheme===1"/>
    <bottom-left-call />
  </div>
</template>

<script>
import agencyMarketer from '@/mixins/agencyMarketer';

export default {
  name: 'searchResult',
  components: {
    ProductPageTheme0: () => import('./ProductPageTheme0'),
    ProductPageTheme1: () => import('./ProductPageTheme1'),
    BottomLeftCall: () => import('@/components/content/BottomLeftCall'),
  },
  mixins: [agencyMarketer],
};
</script>
