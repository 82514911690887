<template>
  <div v-if="odysseyAgentState && !isFCLAgent">
    <Banner :banner="bannerImages"/>
    <div class="my-5 mx-1 hotelplusflight">
      <div class="container">

        <h1 class="text-center"> {{$t("booking-info.personal-area")}} </h1>

        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          :first-text="$t('pending-info.first')"
          :prev-text="$t('pending-info.prev')"
          :next-text="$t('pending-info.next')"
          :last-text="$t('pending-info.last')"
          class="p-0"
        ></b-pagination>

        <b-table responsive striped sticky-header="600px" no-border-collapse outlined table-variant="light" head-variant="light"
        :per-page="perPage"
        :current-page="currentPage"
        :busy.sync="isBusy"
        :items="items" :fields="fields">

          <template #cell(tag)="row">
            <b-form-select :value="row.item.tag" @change="(value) => changeTag(value, row.item.transId)" :options="options"></b-form-select>
          </template>

          <template #cell(hotelOrderNum)="row">
            <span v-if="!row.item.hotelSource && row.item.pdfUrlHotelVoucher">{{ $t('pending-info.confirmed') }}</span>
            <span v-if="row.item.hotelSource && row.item.hotelOrderNum !== null">{{ row.item.hotelOrderNum }}</span>
            <span v-else>{{ $t('pending-info.unconfirmed') }}</span>
          </template>

          <template #cell(pdfUrlHotelVoucher)="row">
            <div class="icon_box" v-if="row.item.pdfUrlHotelVoucher">
              <b-link
                :href="row.item.pdfUrlHotelVoucher"
                @click.prevent="downloadTickets(row.item.pdfUrlHotelVoucher, 'HotelVoucher')"
                ><img src="/assets/img/after-booking-icon18.png"  alt="after-booking-icon"
              /></b-link>
            </div>
          </template>

          <template #cell(destination)="row">
            <div>{{ row.item.destination }} - {{ row.item.destinationCode }}</div>
          </template>
          <template #cell(price)="row">
            <div>₪{{ row.item.price }} </div>
          </template>
          <template #cell(agentNetto)="row">
            <div v-if="row.item.agentNetto !== null">₪{{ row.item.agentNetto }} </div>
          </template>
          <template #cell(totalPaid)="row">
            <div v-if="row.item.index === currentBookingIndex && submitPrice">₪{{ totalPaid }} </div>
            <div v-if="row.item.totalPaid !== null">₪{{ row.item.totalPaid }} </div>
          </template>
          <template #cell(totalDebtToFc)="row">
            <div v-if="row.item.index === currentBookingIndex && submitPrice"><span v-if="!isNaN(totalDebtToFc)">₪</span>{{ totalDebtToFc }} </div>
            <div v-if="row.item.totalDebtToFc !== null">₪{{ row.item.totalDebtToFc }} </div>
          </template>

          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>{{$t('pending-info.loading')}}</strong>
            </div>
          </template>

          <template #cell(direct_payment_action)="row">
            <b-button size="sm" @click="gotoBook(row.item.index, row.item.pnr)" class="mr-2">
              Direct Payment
            </b-button>
          </template>

        </b-table>
      </div>
    </div>
    <Footer page="home" v-if="!hideCondition"/>

    <b-modal id="inputPrice" centered size="lg" :dir="lang == 'he' ? 'rtl' : 'ltr'" :no-close-on-backdrop="true" :ok-only="true">
      <template #modal-header="{}">
        <div class="d-flex flex-grow-1 align-items-baseline" :class="[lang == 'he' ? 'text-right' : 'text-left']">
          <h3 class="m-auto">{{$t('pending-info.direct-payment-input-price-modal-title', {pnr: currentPnr})}} <span v-if="submitPrice">₪</span>{{ submitPrice }}</h3>
        </div>
      </template>
      <template>
        <b-form-group label="Price:" label-for="input-3">
          <b-form-input
            v-model="submitPrice"
            type="number"
            class="agent-amount"
          ></b-form-input>
        </b-form-group>
      </template>
      <template #modal-footer="{ ok }">
        <b-button size="lg" variant="success" @click="directPayment(); ok();">
          {{ $t("booking.ok") }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import { BTable, BFormSelect, BLink, BPagination, BSpinner } from 'bootstrap-vue';
import 'vue-select/dist/vue-select.css';
import { BTable, BModal, BButton, BFormGroup, BFormInput, BFormSelect, BLink, BPagination, BSpinner } from 'bootstrap-vue';
import getProductBanner from '@/mixins/getProductBanner';
import { toBase64 } from '@/utils/base64Util';

export default {
  name: 'BookingInfoPage',
  mixins: [getProductBanner],
  data() {
    return {
      fields: [
        // {
        //   key: 'tag',
        //   label: 'Option',
        // },
        {
          key: 'pnr',
          label: 'Atlantis PNR',
        },
        {
          key: 'hotelOrderNum',
          label: 'Confirmation Number',
        },
        {
          key: 'hotelName',
          label: 'Hotel Name',
        },
        {
          key: 'destination',
          label: 'Destination',
        },
        {
          key: 'checkinDate',
          label: 'CheckIn',
        },
        {
          key: 'checkoutDate',
          label: 'CheckOut',
        },
        {
          key: 'price',
          label: 'Price',
        },
        {
          key: 'agentNetto',
          label: 'Net Price',
        },
        {
          key: 'totalPaid',
          label: 'Total Paid',
        },
        {
          key: 'totalDebtToFc',
          label: 'Total Debit To Fc',
        },
        {
          key: 'pdfUrlHotelVoucher',
          label: 'Hotel Voucher',
        },
        {
          key: 'direct_payment_action',
          label: 'Direct Payment',
        },
      ],
      delayMinute: 13,
      // items: [],
      refreshIntervalId: null,
      currentIndex: null,

      perPage: 5,
      currentPage: 1,

      currentBookingIndex: undefined,
      currentPnr: '',
      submitPrice: null,
    };
  },
  components: {
    BTable,
    BLink,
    BPagination,
    BFormSelect,
    BSpinner,
    BModal,
    BButton,
    BFormGroup,
    BFormInput,
    Footer: () => import('@/components/content/footer/FooterTheme0'),
    Banner: () => import('@/components/productPage/productBanner/ProductBannerTheme0'),
  },
  computed: {
    ...mapGetters({
      isLoading: 'GET_LOADING_STATE',
      lang: 'GET_LANGUAGE',
      device: 'GET_DEVICE',
      allBookingInfoList: 'GET_BOOKING_INFO_LIST',
      isBusy: 'GET_LOADING_BOOKING_INFO',
      hideCondition: 'GET_HIDE_CONDITION',
      odysseyAgentState: 'GET_ODYSSEY_AGENT_STATE',
      isFCLAgent: 'IS_FCL_ODYSSEY_AGENT_CODE',
    }),
    rows() {
      return this.items.length;
    },
    items() {
      const { allBookingInfoList } = this;
      if (!allBookingInfoList) return [];
      return allBookingInfoList.map((elm, inx) => ({ ...elm, index: inx }));
    },
    totalPaid() {
      const { submitPrice } = this;
      return submitPrice;
    },
    totalDebtToFc() {
      const { submitPrice, currentBookingIndex, allBookingInfoList } = this;
      const currentBookingInfo = allBookingInfoList[currentBookingIndex];

      const debit = currentBookingInfo.agentNetto - submitPrice;

      return (debit < 0) ? 'ERROR' : debit;
    },
  },
  watch: {
  },
  created() {
    this.$store.commit('SET_CURRENT_PAGE', 'booking-info');
    if (!this.odysseyAgentState || this.isFCLAgent) {
      this.$router.push(`/?lang=${this.lang}`);
    } else {
      this.$store.dispatch('FETCH_BOOKING_INFO_LIST');
    }

    /**
     * Initialize direct payment state
     */
    window.localStorage.removeItem('direct-payment');
  },
  methods: {
    downloadTickets(pURL, pName) {
      this.$bvToast.toast(this.$t('booking.waiting-downloading'), {
        title: this.$t('booking.downloading'),
        autoHideDelay: 5000,
        appendToast: true,
        variant: 'info',
        noCloseButton: true,
        bodyClass: this.lang === 'he' ? 'rtl' : 'ltr',
      });
      const fileName = `${pName}.pdf`;
      if (!window.ActiveXObject) {
        const save = document.createElement('a');
        save.href = pURL;
        save.target = '_blank';
        save.download = fileName;
        if (navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search('Chrome') < 0) {
          document.location = save.href;
        } else {
          const evt = new MouseEvent('click');
          save.dispatchEvent(evt);
          (window.URL || window.webkitURL).revokeObjectURL(save.href);
        }
      } else if (!!window.ActiveXObject && document.execCommand) {
        const _window = window.open(pURL, '_blank');
        _window.document.close();
        _window.document.execCommand('SaveAs', true, fileName);
        _window.close();
      }
    },
    async directPayment() {
      if (!this.submitPrice) {
        this.$bvModal.msgBoxConfirm('The price must be set.', {
          title: 'error state',
          okVariant: 'success',
          headerClass: 'p-2 border-bottom-0 price-compare',
          footerClass: 'p-2 border-top-0',
          centered: true,
          okTitle: this.$t('login.ok'),
          hideHeaderClose: false,
        });
        return;
      }
      const inx = this.currentBookingIndex === undefined ? 0 : this.currentBookingIndex;
      const supplierCodeList = process.env.VUE_APP_SUPPLIER_LIST.split(',');
      const currentBookingInfo = this.allBookingInfoList[inx];
      const { bookTransactionDesc } = currentBookingInfo;
      const bookingTransId = bookTransactionDesc.guid;
      const { pnr } = bookTransactionDesc;

      const bookingInfo = await this.$store.dispatch('FETCH_BOOKING_INFO_FOR_DIRECT_PAYMENT', { bookingTransId });

      if (!bookingInfo) return;

      const totalPrice = this.submitPrice; // currentBookingInfo.price;
      const netPrice = currentBookingInfo.agentNetto;

      const product = {
        agentScreenResolultion: this.device,
        grandTotalAfterDiscount: totalPrice,
        netPrice,
        priceBeforeCoupon: netPrice,
        couponCode: '',
        currencyCode: bookTransactionDesc.currency,
        hotel: {
          source: bookTransactionDesc.hotel.source,
          hotelCode: bookTransactionDesc.hotel.hotelCode,
          cityName: bookTransactionDesc.hotel.cityName,
          checkinDate: bookTransactionDesc.hotel.checkinDate,
          checkoutDate: bookTransactionDesc.hotel.checkoutDate,
          remarkInVoucher: bookTransactionDesc.hotel.remarkInVoucher,
          hotelName: bookTransactionDesc.hotel.hotelName,
          cityCode: bookTransactionDesc.hotel.cityCode,
          hotelSupplierCode: supplierCodeList[bookTransactionDesc.hotel.source],
          rooms: bookingInfo.hotelRoomsInfo.rooms,
        },
        paxList: bookTransactionDesc.paxes,
        payer: bookTransactionDesc.payer,
        dealId: bookTransactionDesc.dealId,
      };

      const data = {
        data: product,
        sendingStage: 'direct-payment',
      };

      const currency = (currentBookingInfo.currencyCode === 'IS' || currentBookingInfo.currencyCode === 'NIS') ? '₪' : '$';
      const h = this.$createElement;
      const titleVNode = h('div', { class: ['text-center'] }, this.$t('pending-info.direct-payment-modal-title', { pnr }));
      const messageVNode = h('div', { class: ['price-message-body'] }, [
        h('p', { class: ['text-right', 'my-0'] }, [h('strong', `${this.$t('search-result.hotel')}: `), bookTransactionDesc.hotel.hotelName]),
        h('p', { class: ['text-right', 'my-0'] }, [h('strong', `${this.$t('search-result.city')}: `), bookTransactionDesc.hotel.cityName]),
        h('p', { class: ['text-right', 'my-0'] }, [h('strong', `${this.$t('pending-info.direct-payment-old-price', { netPrice: `${currency}${netPrice}` })} `)]),
        h('p', { class: ['text-right', 'my-0'] }, [h('strong', `${this.$t('pending-info.direct-payment-new-price', { totalDebit: `${currency}${totalPrice}` })} `)]),
      ]);
      this.$bvModal.msgBoxConfirm([messageVNode], {
        title: [titleVNode],
        // size: 'lg',
        // buttonSize: 'sm',
        okVariant: 'success',
        headerClass: 'p-2 border-bottom-0 price-compare',
        footerClass: 'p-2 border-top-0',
        centered: true,
        okTitle: this.$t('login.yes'),
        cancelTitle: this.$t('login.no'),
        hideHeaderClose: false,
      })
        .then(async (value) => {
          if (value) {
            const { host } = window.location;
            const http = host === 'localhost:8080' ? 'http://' : 'https://';
            await this.$store.dispatch('SEND_PAYMENT_ONLY', {
              bookingTransId,
              body: {
                successUrl: `${http}${host}/redirect-verified?lang=${this.lang}`,
                failureUrl: `${http}${host}/redirect-verified?lang=${this.lang}`,
                cssUrl: `${http}${host}/assets/css/styleVerify.css`,
                cssUrl2: `${http}${host}/assets/css/styleVerify.css`,
                simulatedPelecardStatusCode: null,
              },
              amount: totalPrice,
            });

            window.localStorage.setItem('BookingDetailInfo', toBase64(JSON.stringify(data)));
            window.localStorage.setItem('production', toBase64(JSON.stringify(product)));
            window.localStorage.setItem('BookingDataID', bookingTransId);
            window.localStorage.setItem('retryState', 'null');
            this.$router.push({ path: `/booking/hotel?lang=${this.lang}` });
            this.submitPrice = '';
          }
        });
    },
    gotoBook(index, pnr) {
      this.currentBookingIndex = index;
      this.currentPnr = pnr;
      this.$bvModal.show('inputPrice');
    },
  },
};
</script>

<style scoped>
 .icon_box {
    width: 40px;
    height: 40px;
    border: 1px solid #0763ab;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px auto 15px;
  }
  .icon_box img {
    width: 30px;
    height: 30px;
  }
</style>
