<template>
  <SearchResultTheme0 v-if="kindTheme === 0 || kindTheme === 2"/>
  <SearchResultTheme1 v-else-if="kindTheme===1"/>
</template>

<script>
import agencyMarketer from '@/mixins/agencyMarketer';

export default {
  name: 'searchResult',
  components: {
    SearchResultTheme0: () => import('./SearchResultTheme0'),
    SearchResultTheme1: () => import('./SearchResultTheme1'),
  },
  mixins: [agencyMarketer],
};
</script>
