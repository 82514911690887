<template>
  <home-theme0 v-if="kindTheme===0" />
  <home-theme1 v-else-if="kindTheme === 1" />
  <home-theme2 v-else-if="kindTheme===2" />
</template>

<script>
import agencyMarketer from '@/mixins/agencyMarketer';

export default {
  name: 'home',
  components: {
    HomeTheme0: () => import('./HomeTheme0'),
    HomeTheme1: () => import('./HomeTheme1'),
    HomeTheme2: () => import('./HomeTheme2'),
  },
  mixins: [agencyMarketer],
};
</script>
