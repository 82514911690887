<template>
  <div v-if="!isDirectPayment">
    <div v-if="device == 'desktop'">
      <div class="holiday-hotel-details">
        <h2> {{ destinationName }}-{{ hotelName }} </h2>
      </div>
      <div class="clearfix"></div>
      <BreadCrumb type="flat" :stage="bookingStage" v-if="device == 'desktop'"/>
    </div>
    <div v-else>
      <mobile-header :product="product" />
      <mobile-head-banner :stage="bookingStage" />
    </div>
    <div class="container p-0">
      <div class="new_booking_main_area dVacation-pack booking_mobile_part mt-3">
        <div class="booking_details_three">
          <div class="price_details" v-if="device === 'desktop'">
            <div class="price_details_heading">
              <h3>{{ $t("booking.reservation-summery") }}</h3>
            </div>
            <div class="price_details_main_body">
              <div class="price_details_body">
                <div>
                  <h4 class="mr-3">{{ hotelName }} : {{ destinationName }}</h4>
                  <RoomInformation v-for="(room, idx) in rooms" :key="idx" :room="room" :idx="idx"/>
                </div>
                <h6 class="mt-3 mb-0 mr-3">{{ remark }}</h6>
              </div>
            </div>
          </div>

          <div  class="p-3 mb-3 w-100 card" v-if="isOdysseySite">
            <internal-agent-info @updateInternalAgentInfo="changeAgentInfo" :fullPrice="product.grandTotalAfterDiscount" :netPrice="netPrice"/>
          </div>

          <div class="passenger_detail">
            <div>
              <div class="passenger_details_main_area booking_mobile_part mt-3">
                <div v-if="device == 'desktop'">
                  <div class="passenger_box_one">
                    <h2>
                      {{ $t("booking.passenger-details") }}
                      <span class="icon"><i class="fas fa-user"></i></span>
                    </h2>
                  </div>
                </div>
                <div class="container guest_details_body" v-else>
                  <h3>
                    {{ $t("booking.guest-details") }}
                    <span class="icon"><i class="fas fa-user"></i></span>
                  </h3>
                  <p>{{ $t("booking.guest-desc") }}</p>
                </div>
                <UserListRoom
                  v-for="(room, idx) in rooms"
                  :key="idx"
                  :room="room"
                  :index="idx"
                  :supplier="supplier"
                  :isExternal="isExternalBookingState"
                  @updatedUser="updatingUser"
                />
              </div>
            </div>
          </div>

          <payer-information @updatePayer="updatingPayer"/>

          <div class="new_booking_main_area dVacation-pack booking_mobile_part mt-md-5">
            <div class="booking_details_three">
              <div class="price_details" v-if="!isOdysseySite">
                <div class="price_details_heading" v-if="device == 'desktop'">
                  <h3>{{ $t("booking.reservation-terms") }}</h3>
                </div>
                <div class="price_details_main_body">
                  <div class="price_details_body row">
                    <div class="terms_box col-lg-8 col-12">
                      <div class="form-check">
                        <label class="form-check-label pr-5">
                          <input type="checkbox" class="form-check-input" v-model="checkedTerms"/>{{ $t("booking.i-agree-to") }}
                         <a href="https://www.flying.co.il/%D7%AA%D7%A7%D7%A0%D7%95%D7%9F_%D7%91%D7%99%D7%98%D7%95%D7%9C_%D7%95%D7%96%D7%99%D7%9B%D7%95%D7%99"><strong>{{ $t('booking.agree-desc') }}</strong></a>
                          <!-- {{ $t('booking.read-desc') }} -->
                          <!-- <a href="https://www.flying.co.il/%D7%94%D7%A0%D7%97%D7%99%D7%95%D7%AA_%D7%9C%D7%99%D7%95%D7%A6%D7%90%D7%99%D7%9D_%D7%9E%D7%99%D7%A9%D7%A8%D7%90%D7%9C_%D7%95%D7%9C%D7%97%D7%95%D7%96%D7%A8%D7%99%D7%9D_%D7%9C%D7%99%D7%A9%D7%A8%D7%90%D7%9C"><strong>{{ $t('booking.read-desc-corona') }}</strong></a> -->
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label pr-5">
                          <input type="checkbox" class="form-check-input"
                            v-model="checkedPromotion" />
                          {{ $t("booking.email-receive-agree") }}
                        </label>
                      </div>
                      <!-- <div class="form-check">
                        <label class="form-check-label pr-5">
                          <input type="checkbox" class="form-check-input"
                            checked
                            v-model="checkedInsurance"/>
                          {{ $t("booking.telephone-provide-agree") }}
                        </label>
                      </div> -->
                    </div>
                    <div class="col-lg-4 col-12" v-if="partnerId === ''">
                      <div class="card p-2 mx-2">
                        <label class="mx-1"><i id="coupon-help" class="fa fa-question-circle"/> {{ $t("booking.coupon-label") }}</label>
                        <b-form-input type="text" v-model="couponCode" @input="initialize" class="form-control my-2" :placeholder="$t('booking.no-coupon')"/>
                        <b-form-text v-if="couponSubmit && isCouponState === 'valid'" :dir="lang==='en' ? 'ltr': ''"> {{$t("booking.valid-message")}} {{discountAmount}} {{$t("booking.coupon-currency-symbol")}}</b-form-text>
                        <b-form-text v-if="couponSubmit && isCouponState === 'invalid'"> {{couponErrorMessage || $t("booking.invalid-message")}}</b-form-text>
                        <b-form-text v-if="couponSubmit && isCouponState === 'error'"> {{couponErrorMessage}}</b-form-text>
                        <button class="btn btn-primary form-control my-2" @click="submitWithCoupon" :disabled="couponSubmit">
                          {{ $t("booking.submit") }}
                        </button>
                        <b-tooltip target="coupon-help" :title="$t('booking.coupon-help')"></b-tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="total_booking_price" v-if="device == 'desktop'">
                <div class="d-flex justify-content-between">
                  <div class="price_box">
                    <h3>
                      <!-- <span>{{ $t("booking.total-price") }}:</span>{{ totalPrice }} ₪ -->
                    </h3>
                  </div>
                  <div class="price_box">
                    <h3>
                      <span>{{ $t("booking.total-to-price") }}:</span>{{ totalPrice }} ₪
                    </h3>
                  </div>
                  <button class="btn-booking" @click="BookingNow" :disabled="disableBook" :class="disAllow && 'dis-allow'">
                    {{ $t("search-result.book-now") }}
                  </button>
                </div>
              </div>
              <!-- <div class="total_payment" v-else>
                <div class="container">
                  <div class="d-flex justify-content-between">
                    <h4>
                      <span>{{ $t("booking.total-payment") }}</span>{{ totalPrice }} ₪
                    </h4>
                    <b-button class="btn" @click="BookingNow" :disabled="disableBook" size="lg">
                      {{ $t("search-result.book-now") }}
                    </b-button>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="total_payment" v-if="device==='mobile'">
      <div class="container">
        <div class="d-flex justify-content-between">
          <h4>
            <span>{{ $t("booking.total-payment") }}</span>{{ totalPrice }} ₪
          </h4>
          <b-button class="btn" @click="BookingNow" :disabled="disableBook" size="lg" :class="disAllow && 'dis-allow'">
            {{ $t("search-result.book-now") }}
          </b-button>
        </div>
      </div>
    </div>

    <b-modal id="countDownModal" centered size="lg" :dir="lang == 'he' ? 'rtl' : 'ltr'" :no-close-on-backdrop="true" :ok-only="true">
      <template #modal-header="{}">
        <div class="d-flex flex-grow-1 align-items-baseline" :class="[lang == 'he' ? 'text-right' : 'text-left']">
          <h3 class="m-auto">{{ $t("booking.notice") }}</h3>
          <h5>{{ maxSec }} {{ $t("booking.sec") }}</h5>
        </div>
      </template>
      <template>
        <span :class="[lang == 'he' ? 'text-right' : 'text-left']">{{ $t("booking.go-product-page") }}</span>
      </template>
      <template #modal-footer="{ ok }">
        <b-button size="lg" variant="success" @click="gotoProductPage(); ok();">
          {{ $t("booking.ok") }}
        </b-button>
      </template>
    </b-modal>

    <b-modal id="NoFillCardModal" centered size="lg" :dir="lang == 'he' ? 'rtl' : 'ltr'" :no-close-on-backdrop="true" :ok-only="true">
      <template #modal-header="{}">
        <div class="d-flex flex-grow-1 align-items-baseline" :class="[lang == 'he' ? 'text-right' : 'text-left']">
          <h3 class="m-auto">{{ $t("booking.notice") }}</h3>
          <h5>{{ maxSecNoFill }} {{ $t("booking.sec") }}</h5>
        </div>
      </template>
      <template>
        <span :class="[lang == 'he' ? 'text-right' : 'text-left']">{{ $t("booking.no-fill-pax-info") }}</span>
      </template>
      <template #modal-footer="{ ok }">
        <b-button size="lg" variant="success" @click="gotoProductPage(); ok();">
          {{ $t("booking.ok") }}
        </b-button>
      </template>
    </b-modal>

    <b-toast id="countDown" toaster="b-toaster-top-center" variant="warning" solid v-model="showCountDown" :classes="[lang == 'he' ? 'rtl' : 'ltr']">
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-baseline">
          <h5>{{ maxSec }} {{ $t("booking.sec") }}</h5>
          <h3 class="m-auto">{{ $t("booking.notice") }}</h3>
        </div>
      </template>
      {{ $t("booking.go-product-page") }}
    </b-toast>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BToast, BModal, BButton, BTooltip, BFormInput, BFormText } from 'bootstrap-vue';
import dayjs from 'dayjs';
import { fromBase64, toBase64 } from '@/utils/base64Util';

export default {
  components: {
    BToast,
    BTooltip,
    BFormText,
    BFormInput,
    BButton,
    BModal,
    MobileHeader: () => import('@/components/booking/atoms/MobileHeader'),
    MobileHeadBanner: () => import('@/components/booking/atoms/MobileHeadBanner'),
    BreadCrumb: () => import('@/components/productPage/Breadcrumb'),
    RoomInformation: () => import('@/components/booking/atoms/RoomInformation'),
    UserListRoom: () => import('@/components/booking/atoms/UserListRoom'),
    PayerInformation: () => import('@/components/booking/atoms/PayerInformation'),
    InternalAgentInfo: () => import('@/components/booking/InternalAgentInfo'),
  },
  data() {
    return {
      query: this.$route.query,
      bookingStage: 2,

      product: null,
      // category: null,
      hotelName: '',
      rooms: [],
      flightItems: [],
      // categoryCode: '',
      userList: [],

      payerInfo: {
        firstNamePayer: '',
        lastNamePayer: '',
        phonePayer: '',
        emailPayer: '',
        idCardNumberPayer: '',
        creditCardExpirYear: '',
        creditCardExpirMonth: '',
        noOfPayment: '1',
      },

      totalPrice: 0,
      originalPrice: 0,
      netPrice: 0,

      availableUsers: false,
      validateUsers: false,
      availablePayer: false,

      checkedTerms: false,
      checkedPromotion: false,
      // checkedInsurance: true,

      disableBook: false,
      timeout: -1,

      showCountDown: false,
      maxSec: 10,
      maxSecNoFill: 30,

      supplier: '',

      timeID: -1,
      remark: '',
      discountAmount: '',

      couponSubmit: false,
      couponValue: 0,
      isCouponState: 'unset', // valid, invalid, error, unset
      couponCode: '',
      couponErrorMessage: '',

      internalAgentInfo: null,
      internalAgentNisPrice: 0,
    };
  },
  computed: {
    ...mapGetters({
      bookingDataID: 'GET_BOOKING_DATA_ID',
      device: 'GET_DEVICE',
      lang: 'GET_LANGUAGE',
      devMode: 'GET_MODE',
      marketerId: 'GET_MARKETER_ID',
      subMarketerId: 'GET_SUB_AGENCY',
      partnerId: 'GET_PARTNER_ID',
      bypassPaymentState: 'GET_BYPASS_PAYMENT_STATE',
      isOdysseySite: 'GET_ODYSSEY_AGENT_STATE',
      clerkName: 'GET_ODYSSEY_AGENT_CLERK_NAME',
      isFCLAgent: 'IS_FCL_ODYSSEY_AGENT_CODE',
      externalBookingInfo: 'GET_EXTERNAL_BOOKING_INFO',
      agencyOwnerName: 'GET_AGENCY_OWNER_NAME',
      affiliation: 'GET_AFFILIATION',

      isDirectPayment: 'GET_DIRECT_PAYMENT_STATE',
      isDirectPaymentResponse: 'GET_PAYMENT_ONLY_RES',
    }),
    isExternalBookingState() {
      return this.externalBookingInfo !== null;
    },
    disAllow() {
      const { validateUsers, bypassPaymentState, availablePayer, isOdysseySite, checkedTerms } = this;
      return (!bypassPaymentState && !availablePayer) || !validateUsers || (!isOdysseySite && !checkedTerms);
    },
  },
  created() {
    window.localStorage.removeItem('getPaymentResponse');
    if (this.isDirectPayment) {
      /**
       * this is the direct payment flow from external booking page.
       */
      this.gotoPaymentPage(this.isDirectPaymentResponse);
      return;
    }

    window.scrollTo(0, 0);
    this.disableBook = false;
    this.$emit('setBookingStage', 2);
    this.fetchData();

    const retry = JSON.parse(window.localStorage.getItem('retryState'));
    const dataBook = JSON.parse(fromBase64(window.localStorage.getItem('BookingDetailInfo')));
    const { payer } = dataBook.data;
    const { sendingStage } = dataBook;

    if (retry != null && sendingStage !== 'init' && !this.bypassPaymentState) {
      if (payer.name.length > 0) {
        const name = payer.name.split(' ');
        this.payerInfo.firstNamePayer = name[0];
        this.payerInfo.lastNamePayer = name[1];
      }
      this.payerInfo.phonePayer = payer.mobile;
      this.payerInfo.emailPayer = payer.email;
      this.payerInfo.idCardNumberPayer = payer.identificationNumber;
      this.payerInfo.creditCardExpirYear = payer.creditCardExpirYear;
      this.payerInfo.creditCardExpirMonth = payer.creditCardExpirMonth;
      this.payerInfo.noOfPayment = Number(payer.noOfPayment);
    } else if (this.bypassPaymentState) {
      this.payerInfo.idCardNumberPayer = payer.identificationNumber;
    }
    window.scrollTo(0, 0);
    // window.addEventListener('beforeunload', this.ClosingWindow);
  },
  mounted() {
    const retry = JSON.parse(window.localStorage.getItem('retryState'));
    const dataBook = JSON.parse(fromBase64(window.localStorage.getItem('BookingDetailInfo')));
    const bookingDataID = this.bookingDataID || window.localStorage.getItem('BookingDataID');
    const { sendingStage } = dataBook;

    const { host } = window.location;
    if (host.includes('localhost') || host.includes('vercel')) {
      setTimeout(() => {
        this.$bvModal.show('NoFillCardModal');
        this.timeID = setInterval(() => {
          this.maxSecNoFill -= 1;
          if (this.maxSecNoFill === 0) {
            this.gotoProductPage();
          }
        }, 1000);
      }, 1000 * 60 * 20);
    }

    if ((retry == null && sendingStage !== 'init' && sendingStage !== 'external' && sendingStage !== 'direct-payment') || !bookingDataID) {
      this.$bvModal.show('countDownModal');

      this.timeID = setInterval(() => {
        this.maxSec -= 1;
        if (this.maxSec === 0) {
          this.gotoProductPage();
        }
      }, 1000);
    }
    if (retry) {
      this.validateUsers = retry.readOnly;
    }

    dataBook.sendingStage = 'update';
    // window.localStorage.setItem('BookingDetailInfo', toBase64(JSON.stringify(dataBook)));
    if (!this.isOdysseySite) {
      const { grandTotalAfterDiscount, hotel: { hotelName, cityName } } = this.product;
      const name = `${hotelName} - ${cityName} - ${this.agencyOwnerName}`;
      const gaEventLegacyData = sessionStorage.getItem('ga-event-data');
      const pasedData = gaEventLegacyData ? JSON.parse(gaEventLegacyData) : null;

      const dest = pasedData ? pasedData.destination : '';
      const checkIn = pasedData ? pasedData.checkIn : '';
      const checkOut = pasedData ? pasedData.checkOut : '';

      this.$store.dispatch('SENDING_GA_EVENT', {
        eventName: 'add_to_cart',
        items: [
          {
            item_id: bookingDataID,
            name,
            brand: name,
            affiliation: this.affiliation,
            item_category: dest,
            item_category2: `${checkIn} - ${checkOut}`,
            price: grandTotalAfterDiscount,
          },
        ],
      });

      // window.gtag('event', 'add_to_cart', {
      //   items: [
      //     {
      //       id: bookingDataID, // please assign number
      //       name,
      //       list_name: '',
      //       brand: name,
      //       category: '',
      //       variant: '',
      //       list_position: 1,
      //       quantity: 1,
      //       price: grandTotalAfterDiscount,

      //       affiliation: this.affiliation,
      //       coupon: '',
      //       discount: '',
      //       index: 0,
      //       item_brand: '',
      //       item_category: dest,
      //       item_category2: `${checkIn} - ${checkOut}`,
      //       item_category3: '',
      //       item_category4: '',
      //       item_category5: '',
      //       item_list_id: '',
      //       item_list_name: '',
      //       item_variant: '',
      //       location_id: '',
      //     },
      //   ],
      // });
    }
    this.$store.dispatch('JOURNEY_OPEN_PAX_PAGE');
  },
  destroyed() {
    // window.removeEventListener('beforeunload', this.ClosingWindow);
  },
  methods: {
    async fetchData() {
      this.product = JSON.parse(fromBase64(window.localStorage.getItem('production')));
      this.rooms = this.product.hotel.rooms;
      this.originalPrice = this.product.grandTotalAfterDiscount;
      this.totalPrice = this.product.grandTotalAfterDiscount;
      this.netPrice = this.product.netPrice;
      this.supplier = this.product.hotel.hotelSupplierCode;

      this.rooms.forEach((room) => {
        room.checkIn = this.product.hotel.checkinDate;
        room.checkOut = this.product.hotel.checkoutDate;
        room.NoNight = this.getDuration(this.product.hotel.checkinDate, this.product.hotel.checkoutDate);
      });
      this.hotelName = this.product.hotel.hotelName;
      this.destinationName = this.product.hotel.cityName;

      this.remark = this.product.hotel.remarkInVoucher;

      if (document.querySelector('.st-content')) document.querySelector('.st-content').scrollTo(0, 0);
    },
    getDuration(checkIn, checkOut) {
      return dayjs(checkOut).diff(dayjs(checkIn), 'day');
    },
    updatingUser(updatedUser) {
      const user = this.userList.filter((pUser) => pUser.id === updatedUser.id && pUser.groupID === updatedUser.groupID)[0];
      if (user !== undefined) {
        Object.assign(user, updatedUser);
      } else {
        this.userList.push(updatedUser);
      }
      this.validateUsers = true;
      this.userList.forEach((usr) => {
        if (!usr.validate) this.validateUsers = false;
      });
    },
    updatingPayer(updatedPayer) {
      this.payerInfo = updatedPayer;
      this.availablePayer = updatedPayer.available;
    },
    formattingBookData() {
      if (this.bypassPaymentState) this.availablePayer = true;
      const production = JSON.parse(fromBase64(window.localStorage.getItem('production')));
      let userID = 0;
      const userIdList = [];
      this.availableUsers = true;
      this.userList.forEach((user) => {
        userID += 1;
        user.id = String(userID);
        // user.identificationNumber = this.payerInfo.idCardNumberPayer;
        userIdList.push(String(userID));
        if (!user.validate) this.availableUsers = false;
      });

      const paxList = [];
      this.userList.forEach((user) => {
        const clone = { ...user };
        clone.birthDate = dayjs().subtract(25, 'year').format('YYYY-MM-DD').toString();
        delete clone.groupID;
        // clone.identificationNumber = process.env.VUE_APP_ID_NUMBER;
        paxList.push(clone);
      });

      let roomIndex = 0;
      this.rooms.forEach((room) => {
        const UIDRoom = [];
        this.userList.forEach((user) => {
          if (user.groupID === roomIndex) {
            UIDRoom.push(String(user.id));
          }
        });
        room.paxIds = UIDRoom;

        roomIndex += 1;
      });

      production.partnerId = this.partnerId;
      production.couponCode = (this.partnerId === '' && this.isCouponState) ? this.couponCode : '';
      production.grandTotalAfterDiscount = this.totalPrice;
      production.hotel.rooms = this.rooms;
      production.paxList = paxList;
      production.payer = (this.bypassPaymentState) ? {
        identificationNumber: this.payerInfo.idCardNumberPayer,
      } : {
        name: `${this.payerInfo.firstNamePayer} ${this.payerInfo.lastNamePayer}`,
        email: this.payerInfo.emailPayer,
        mobile: this.payerInfo.phonePayer,
        identificationNumber: this.payerInfo.idCardNumberPayer,
        creditCardExpirMonth: this.payerInfo.creditCardExpirMonth,
        creditCardExpirYear: this.payerInfo.creditCardExpirYear,
        noOfPayment: Number(this.payerInfo.noOfPayment),
      };
      production.sendPromotionalProductMails = this.checkedPromotion;
      // production.sendPromotionalInsuranceMails = this.checkedInsurance;
      production.dealId = this.query.dealId || '';

      if (this.isOdysseySite) {
        production.ClerkNameByAgent = this.internalAgentInfo?.clerkName;
        production.OdyAgentCodeByAgent = this.internalAgentInfo?.OdyAgentCodeByAgent || '';
        production.DiscountAmountByAgent = this.product.grandTotalAfterDiscount - this.internalAgentInfo?.SalesAmount || 0;
        production.partialPayment = this.internalAgentInfo?.partialPayment || null;
        production.pnrToUpdate = this.internalAgentInfo?.pnrToUpdate || null;
        production.BypassPaymentByAgent = this.internalAgentInfo?.BypassPaymentByAgent || false;
        production.tourOpIsIntatl19ByAgent = this.internalAgentInfo?.tourOpIsIntatl19ByAgent || false;
        production.remarkByAgent = this.internalAgentInfo?.remarkByAgent || '';
        production.remarkToHotel = this.internalAgentInfo?.remarkToHotel || '';
        production.atlantisID = this.internalAgentInfo?.atlantisID || '';
      }
      production.priceBeforeCoupon = this.originalPrice - (production.DiscountAmountByAgent || 0);
      return {
        data: production,
        sendingStage: 'update',
      };
    },
    async sendBookingInformation() {
      const response = await this.$store.dispatch('SEND_BOOKING_DATA', this.formattingBookData());
      return response;
    },
    async BookingNow() {
      if (this.isExternalBookingState) {
        this.disableBook = true;
        const { bookingTransId, body } = this.externalBookingInfo;
        await this.$store.dispatch('POST_FINALIZE_PENDING_EXTERNAL_BOOK', { bookingTransId, body });
        this.$router.push({ path: `/external-booking?lang=${this.lang}` });
        return;
      }
      if (this.isOdysseySite && this.internalAgentInfo?.clerkName === '') {
        this.$bvToast.toast(this.$t('booking.must-choose-clerk'), {
          title: 'error',
          autoHideDelay: 5000,
          appendToast: true,
          variant: 'danger',
          toaster: 'b-toaster-top-center',
          noCloseButton: true,
          bodyClass: this.lang === 'he' ? 'rtl error6' : 'ltr error6',
        });
        return;
      }

      /**
       * Checking the booking allowing state
       */
      if (!this.checkingPayerAndPax()) return;
      if (!this.checkingTerms()) return;

      // if (!this.isOdysseySite && !this.checkedTerms) {
      //   const message = this.$t('booking.toast-confirm-reservation');
      //   this.$bvToast.toast(
      //     message,
      //     {
      //       title: this.$t('booking.toast-information'),
      //       autoHideDelay: 500000,
      //       appendToast: true,
      //       variant: 'danger',
      //       toaster: 'b-toaster-top-right',
      //       noCloseButton: true,
      //       bodyClass: this.lang === 'he' ? 'rtl' : 'ltr',
      //     },
      //   );
      // } else {
      this.disableBook = true;
      let response = { success: true };
      const retry = window.localStorage.getItem('retryState');
      let savedBookData = true;
      if (retry !== 'null') {
        const oldState = JSON.parse(retry);
        savedBookData = !oldState.readOnly;
        this.availableUsers = oldState.readOnly;
      }
      if (savedBookData) {
        response = await this.sendBookingInformation();
      } else {
        response.id = window.localStorage.getItem('BookingDataID');
      }
      if (response.success) {
        if (this.availablePayer && this.availableUsers) {
          const { host } = window.location;
          const http = host === 'localhost:8080' ? 'http://' : 'https://';
          const info = (this.bypassPaymentState) ? {
            ...this.formattingBookData().data,
            identificationNumber: this.payerInfo.idCardNumberPayer,
          } : {
            ...this.formattingBookData().data,
            email: this.payerInfo.emailPayer,
            mobile: this.payerInfo.phonePayer,
            paymentName: `${this.payerInfo.firstNamePayer} ${this.payerInfo.lastNamePayer}`,
            creditCardExpirMonth: this.payerInfo.creditCardExpirMonth,
            creditCardExpirYear: this.payerInfo.creditCardExpirYear,
            identificationNumber: this.payerInfo.idCardNumberPayer,
            noOfPayment: Number(this.payerInfo.noOfPayment),
            paymentUrlRequest: {
              successUrl: `${http}${host}/redirect-verified?lang=${this.lang}`,
              // failureUrl: `${http}${host}/redirect-verified?lang=${this.lang}`,
              failureUrl: `${http}${host}/fail-card-verified?lang=${this.lang}`,
              cssUrl: `${http}${host}/assets/css/styleVerify.css`,
              cssUrl2: `${http}${host}/assets/css/styleVerify.css`,
              simulatedPelecardStatusCode: null,
            },
          };

          const res = await this.$store.dispatch('GET_PAYMENT_URL', { payerInfo: info, bookID: response.id });

          this.gotoPaymentPage(res);
        } else {
          // let message = this.availablePayer ? this.$t('booking.toast-fill-pax-information') : this.$t('booking.toast-fill-payer-information');
          // if (!this.availablePayer && !this.availableUsers) message = this.$t('booking.toast-pax-payer-information');
          // this.$bvToast.toast(message, {
          //   title: this.$t('booking.toast-information'),
          //   autoHideDelay: 5000,
          //   appendToast: true,
          //   variant: 'danger',
          //   toaster: 'b-toaster-top-right',
          //   noCloseButton: true,
          //   bodyClass: this.lang === 'he' ? 'rtl' : 'ltr',
          // });
          this.disableBook = false;
        }
      } else {
        // let message = "Your booking ID is not available now, so please try booking from previous page again. It will go to previous page 5s later automatically. thanks.";
        // this.$bvToast.toast(
        //   message,
        //   {
        //     title: this.$t('booking.toast-information'),
        //     autoHideDelay: 5000,
        //     appendToast: true,
        //     variant: "danger",
        //     toaster: "b-toaster-top-right",
        //     noCloseButton: true,
        //   }
        // );
        // this.disableBook = false;
        // let oneself = this;
        // setTimeout(function () {
        //   oneself.$router.go(-1);
        // }, 5000);
      }
      // }
    },
    gotoPaymentPage(res) {
      let message = '';
      if (res.success) {
        if (!res.url.error) {
          // if (!window.location.host.includes('vercel') && !this.isOdysseySite) {
          //   const { grandTotalAfterDiscount, hotel: { hotelName, cityName } } = this.product;
          //   const bookingDataID = this.bookingDataID || window.localStorage.getItem('BookingDataID');
          //   const name = `${hotelName} - ${cityName} - ${res.agencyOwnerName}`;
          //   this.gtag('event', 'add_to_cart', {
          //     items: [
          //       {
          //         id: bookingDataID, // please assign number
          //         name,
          //         list_name: '',
          //         brand: name,
          //         category: '',
          //         variant: '',
          //         list_position: 1,
          //         quantity: 1,
          //         price: grandTotalAfterDiscount,
          //       },
          //     ],
          //   });
          // }
          const { marketerId, subMarketerId } = this;
          if (this.bypassPaymentState || res.url.totalPayment === 0) {
            window.localStorage.setItem('getPaymentResponse', toBase64(JSON.stringify(res)));
            this.$router.push({ path: `/booking/card-verified?lang=${this.lang}` });
          } else {
            this.$store.dispatch('UPDATE_VERIFYING_URL', res.url.paymentUrl);
            this.$router.push({ path: `/booking/verifying-card?marketerId=${marketerId}&subMarketerId=${subMarketerId}&lang=${this.lang}` });
          }
        } else if (res.url.error.retryAllowed) {
          // let message = res.url.error.message
          //   ? this.$t('booking.toast-fill-payer-information')
          //   : this.$t('booking.toast-pax-payer-information');
          message = this.$t('booking.booking-regular-error-information');

          if (+res.url.error.code === 6) {
            message = res.url.messageToVisitor.replaceAll('</br>', '\n') || this.$t('check-agent-booking.after-check-message');
          }

          this.$bvToast.toast(message, {
            title: `${this.$t('cancel-booking.error-occurred')}`,
            autoHideDelay: 5000,
            appendToast: true,
            variant: 'danger',
            toaster: 'b-toaster-top-center',
            noCloseButton: true,
            bodyClass: this.lang === 'he' ? 'rtl error6' : 'ltr error6',
          });
          this.disableBook = false;
        } else if (+res.url.error.code === 1) {
          this.$router.push({ path: `/booking/card-verified?lang=${this.lang}` }); // ?????? add the marketerId ?
        } else if (+res.url.error.code === 2) {
          this.$bvToast.toast(this.$t('booking.pending-booking-message'), {
            title: res.url.error.code,
            autoHideDelay: 5000,
            appendToast: true,
            variant: 'success',
            toaster: 'b-toaster-top-right',
            noCloseButton: true,
          });
          this.disableBook = true;
        } else if (+res.url.error.code === 6) {
          this.$bvToast.toast(res.url.messageToVisitor.replaceAll('</br>', '\n') || this.$t('check-agent-booking.after-check-message'), {
            title: `${this.$t('cancel-booking.error-occurred')} ${res.url.error.code}`,
            autoHideDelay: 5000,
            appendToast: true,
            variant: 'danger',
            toaster: 'b-toaster-top-center',
            noCloseButton: true,
            bodyClass: this.lang === 'he' ? 'rtl error6' : 'ltr error6',
          });
          this.disableBook = true;
        } else if (this.bypassPaymentState) {
          window.localStorage.setItem('getPaymentResponse', toBase64(JSON.stringify(res)));
          this.$router.push({ path: `/booking/card-verified?lang=${this.lang}` });
        } else {
          // eslint-disable-next-line no-nested-ternary
          message = this.devMode
            ? this.$t('booking.toast-server-internal-error')
            : (this.isDirectPayment)
              ? `${res.url.error.message} ------- ${res.url.messageToVisitor}`
              : res.url.error.message;
          this.$bvToast.toast(message, {
            title: res.url.error.code,
            autoHideDelay: 5000,
            appendToast: true,
            variant: 'danger',
            toaster: 'b-toaster-top-center',
            noCloseButton: true,
            bodyClass: this.lang === 'he' ? 'rtl' : 'ltr',
          });

          if (this.isDirectPayment) {
            setTimeout(() => { this.$router.push({ path: '/personal-area' }); }, 5000);
          } else {
            setTimeout(() => { this.$router.push({ path: `/?lang=${this.lang}` }); }, 5000);
          }
        }
      } else if (res.error?.response?.status === 400) {
        message = this.$t('booking.toast-fill-payer-information');
        this.$bvToast.toast(message, {
          title: res.error.response.title,
          autoHideDelay: 5000,
          appendToast: true,
          variant: 'danger',
          toaster: 'b-toaster-top-right',
          noCloseButton: true,
          bodyClass: this.lang === 'he' ? 'rtl' : 'ltr',
        });
        this.disableBook = false;
      } else {
        message = this.devMode
          ? this.$t('booking.toast-server-internal-error')
          : res.error.message;
        this.$bvToast.toast(message, {
          title: res.error.code,
          autoHideDelay: 5000,
          appendToast: true,
          variant: 'danger',
          toaster: 'b-toaster-top-right',
          noCloseButton: true,
          bodyClass: this.lang === 'he' ? 'rtl' : 'ltr',
        });
        // const oneself = this;
        setTimeout(() => { this.$router.push({ path: `/?lang=${this.lang}` }); }, 5000);
      }
    },
    async ClosingWindow() {
      const data = this.formattingBookData();
      const bookingDataID = this.bookingDataID || window.localStorage.getItem('BookingDataID');
      this.$store.dispatch('SEND_CLOSING_NOTICE', {
        bookDataID: bookingDataID,
        data: JSON.stringify(data.data),
        withData: true,
      });
    },
    gotoProductPage() {
      clearInterval(this.timeID);
      this.$bvModal.hide('countDownModal');
      // this.$store.dispatch('REDIRECT_TO_PRODUCT');
      this.$router.go(-1);
    },
    initialize() {
      this.couponSubmit = false;
      this.isCouponState = 'unset';
      this.totalPrice = this.originalPrice;
    },
    async submitWithCoupon() {
      if (this.couponCode.trim() === '') return;
      const supplier = this.product?.hotel?.hotelSupplierCode;
      const { dealId } = this.query;
      const { originalPrice } = this;

      // initialize coupon variable
      this.couponSubmit = true;
      this.isCouponState = 'unset';
      this.totalPrice = originalPrice;

      const response = await this.$store.dispatch('FETCH_COUPON_INFO', { code: this.couponCode, originalPrice, checkInDate: this.rooms[0]?.checkIn, supplier, dealId });
      if (response.status === 400 || response.status === 500) {
        this.isCouponState = 'error';
        this.couponErrorMessage = this.$t(response.errors.field[0]);
      } else {
        this.couponValue = response.amountAfterDiscount;
        this.totalPrice = response.available ? response.amountAfterDiscount : this.originalPrice;
        this.isCouponState = response.available ? 'valid' : 'invalid';
        this.couponCode = response.couponCode;
        this.couponErrorMessage = response.remark;
        this.discountAmount = response.discountAmount;
      }
    },
    changeAgentInfo(info) {
      this.internalAgentInfo = { ...info };
      this.totalPrice = this.internalAgentInfo?.SalesAmount;
    },
    checkingPayerAndPax() {
      const { validateUsers, bypassPaymentState, availablePayer } = this;
      let message = '';
      if (!bypassPaymentState && !availablePayer) {
        message = this.$t('booking.toast-fill-payer-information');
      }
      if (!validateUsers) {
        message = this.$t('booking.toast-fill-pax-information');
      }
      if ((!bypassPaymentState && !availablePayer) && !validateUsers) {
        message = this.$t('booking.toast-pax-payer-information');
      }

      if ((!bypassPaymentState && !availablePayer) || !validateUsers) {
        this.$bvToast.toast(message, {
          title: this.$t('booking.toast-information'),
          autoHideDelay: 5000,
          appendToast: true,
          variant: 'danger',
          toaster: 'b-toaster-top-right',
          noCloseButton: true,
          solid: true,
          bodyClass: this.lang === 'he' ? 'rtl' : 'ltr',
        });
        return false;
      }
      return true;
    },
    checkingTerms() {
      const { isOdysseySite, checkedTerms } = this;
      if (!isOdysseySite && !checkedTerms) {
        const message = this.$t('booking.toast-confirm-reservation');
        this.$bvToast.toast(
          message,
          {
            title: this.$t('booking.toast-information'),
            autoHideDelay: 5000,
            appendToast: true,
            variant: 'danger',
            toaster: 'b-toaster-top-right',
            noCloseButton: true,
            solid: true,
            bodyClass: this.lang === 'he' ? 'rtl' : 'ltr',
          },
        );
        return false;
      }
      return true;
    },
  },
};
</script>

<style>
.modal .modal-dialog {
  display: flex;
  font-family: "ploni", "Poppins", sans-serif;
  font-weight: 500;
}
.modal:before {
  display: none;
}
#countDownModal.modal .modal-body {
  display: flex;
}
/* #countDown .ltr {
  direction: ltr;
  text-align: left;
}
#countDown .rtl {
  direction: rtl;
  text-align: right;
} */
.toast-body.rtl {
  direction: rtl;
  text-align: right;
}
.b-toast div.toast-body.error6{
  font-weight: bold;
}
</style>

<style scoped>
#coupon-help{
  color:#0162ac;
}
#countDown h5 {
  padding: 15px 5px;
  font-size: 15px;
}
#countDown h3 {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}
.price_details_heading {
  padding: 20px 30px;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    180deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(1, 98, 172, 1) 0%,
    rgba(38, 135, 209, 1) 100%
  );
}

.price_details_main_body {
  padding: 30px 30px;
  border-left: 1px solid #d3d3d3;
  border-right: 1px solid #d3d3d3;
  border-bottom: 1px solid #d3d3d3;
}

.price_details_heading h3 {
  color: #ffffff;
  font-size: 24px;
  margin: 0 0;
  padding: 0 0;
  text-transform: uppercase;
}

.price_details_body h4 {
  color: #000;
  text-transform: capitalize;
  font-size: 25px;
  margin-bottom: 2px;
}

.rating {
  height: auto;
  color: black;
  background: transparent;
  border: none;
}

.passenger_details_main_area .passenger_box_one {
  background: linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(1,98,172,1) 0%, rgba(38,135,209,1) 100%);
  height: 100px;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 5px;
  position: relative;
  margin-bottom: 15px;
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 30px 20px;
}

.passenger_details_main_area .passenger_box_one h2 {
  margin: 0 0 0;
  padding: 0 0 0;
  color: #fff;
  position: relative;
  font-size: 30px;
  text-transform: capitalize;
  padding-right: 45px;
  direction: ltr;
}

.passenger_details_main_area {
  color: #fff;
}
.passenger_details_main_area .passenger_box_one h2 .icon {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  font-size: 30px;
}

.passenger_box_two {
  border-radius: 5px;
}

.terms_box .form-check {
  margin-bottom: 10px;
}

.terms_box .form-check label {
  font-size: 15px;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-right: -1.25rem;
}

.form-check label strong {
  font-weight: 400;
  color: #0061ab;
}

.total_booking_price {
  background: #0161ab;
  padding: 30px 30px;
  box-sizing: border-box;
}

.total_booking_price .btn-booking {
  background: #ffffff;
  color: #0060aa;
  font-size: 16px;
  text-transform: uppercase;
  padding: 12px 24px;
  border: 1px solid #ffffff;
  border-radius: 30px;
  transition: 0.5s;
  cursor: pointer;
  font-weight: 500;
}
.total_booking_price .btn-booking:disabled, .total_booking_price .btn-booking.dis-allow{
  background: #e0dfdf;
  color: #b6b6b6;
}

.total_booking_price .btn-booking:hover:not(:disabled), .total_booking_price .btn-booking:hover:not(.dis-allow) {
  background: #528ab5;
}

.total_booking_price .price_box h3 {
  margin: 0 0 0;
  padding: 0 0 0;
  color: #fff;
  font-size: 3em;
  font-weight: bold;
}

.total_booking_price .price_box h3 span {
  display: block;
  font-size: 17px;
  text-align: right;
  margin-bottom: 2px;
  font-weight: bold;
}

.guest_details_body h3 {
  padding: 0 0 7px;
  font-size: 18px;
  font-weight: bold;
  color: #000;
  text-transform: capitalize;
  border-bottom: 2px solid #bfbfbf;
  margin-bottom: 10px;
  position: relative;
  padding-right: 25px;
}
.guest_details_body h3 .icon {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
}
.guest_details_body p {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 8px;
  color: #212529;
}
#countDown {
  direction: ltr;
}

@media (min-width: 992px) {
  .price_details_main_body {
    padding: 30px 10px;
  }
}

@media (max-width: 479px) {
  .price_details_main_body {
    padding: 4px;
  }

  .terms_box .form-check label {
    font-size: 12px;
    color: #212529;
  }

  .form-check-input {
    margin-top: 0.2rem;
  }
  .form-check {
    margin-bottom: 8px;
  }

  .form-check label strong {
    color: #212529;
    font-weight: bolder;
  }

  .total_payment {
    padding: 10px 0px 10px;
    overflow: hidden;
    box-shadow: 0px -2px 2px rgba(221, 221, 221, 0.5);
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background: #fff;
    z-index: 91;
  }
  .total_payment .btn {
    background: #0061ab;
    color: #fff;
    text-transform: lowercase;
    /* padding: 0 14px; */
    /* line-height: 0; */
    /* height: 34px; */
    /* font-size: 15px; */
    font-weight: 500;
    /* margin-top: 10px; */
  }
  .total_payment .btn:disabled, .total_payment .btn.dis-allow {
    background: #c0c0c0;
    color: #575656;
    border: none;
  }
  .total_payment h4 {
    text-align: center;
    font-weight: bold;
    font-size: 30px;
    margin-right: 10px;
  }
  .total_payment h4 span {
    font-size: 12px;
    font-weight: 400;
    display: block;
    margin-bottom: 5px;
    text-align: center;
  }
}
</style>
