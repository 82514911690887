import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      imagesData: 'GET_BANNER_IMAGES',
    }),
    bannerImages() {
      return this.imagesData?.imagesByCode?.productBanner?.[0]?.image || '';
    },
  },
  created() {
    if (!this.imagesData?.imagesByCode?.productBanner) {
      this.$store.dispatch('FETCH_BANNER_IMAGES', { page: 'product' });
    }
  },
};
