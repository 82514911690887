<template>
  <div>
    <div v-if="loading">
      <content-loading type="product" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { fromBase64 } from '@/utils/base64Util';

export default {
  components: {
    ContentLoading: () => import('@/components/atoms/ContentLoading'),
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      isLoading: 'GET_LOADING_STATE',
      bookingDataID: 'GET_BOOKING_DATA_ID',
      agencyOwnerName: 'GET_AGENCY_OWNER_NAME',
      affiliation: 'GET_AFFILIATION',
      lang: 'GET_LANGUAGE',
    }),
  },
  watch: {
    isLoading() {
      this.loading = this.isLoading;
    },
  },
  async mounted() {
    let marketerId = '', subMarketerId = '';

    if (window.localStorage.getItem('marketer')) {
      const { marketerId: mid, subMarketerId: smid } = JSON.parse(window.localStorage.getItem('marketer'));
      marketerId = mid;
      subMarketerId = smid;
      window.localStorage.removeItem('marketer');
    }

    if (marketerId && marketerId !== '') this.$store.commit('SET_MARKETER_ID', marketerId);
    if (subMarketerId && subMarketerId !== '') this.$store.commit('SET_SUB_AGENCY', subMarketerId);
    const linkRedirect = window.location.href;
    const oldLink = linkRedirect.includes('redirect-verified') ? 'redirect-verified' : 'fail-card-verified';

    // if (linkRedirect.includes('redirect-verified')) {
    // } else
    if (linkRedirect.includes('fail-card-verified') && !this.isOdysseySite) {
      const bookingDataID = this.bookingDataID || window.localStorage.getItem('BookingDataID');
      const product = JSON.parse(fromBase64(window.localStorage.getItem('BookingDetailInfo'))).data;
      const { hotel: { hotelName, cityName, checkinDate, checkoutDate }, priceBeforeCoupon } = product;
      const storedAgencyOwnerName = JSON.parse(fromBase64(window.sessionStorage.getItem('agencyOwner') || ''));
      const name = `${hotelName} - ${cityName} - ${storedAgencyOwnerName || this.agencyOwnerName}`;

      const res = await this.$store.dispatch('GET_BOOKED_INFO', { data: product });

      window.localStorage.setItem('retryState', JSON.stringify({
        readOnly: res.data.error.retryAllowed,
      }));

      this.$store.dispatch('SENDING_GA_EVENT', {
        eventName: 'remove_from_cart',
        value: priceBeforeCoupon,
        currency: 'ILS',
        items: [
          {
            item_id: bookingDataID,
            item_name: name,
            affiliation: this.affiliation,
            item_category: cityName,
            item_category2: `${checkinDate} - ${checkoutDate}`,
            price: priceBeforeCoupon,
          },
        ],
      });
    }
    let path = window.location.href.replace(oldLink, 'booking/card-verified');

    if (marketerId && marketerId !== '') path += `&marketerId=${marketerId}`;
    if (subMarketerId && subMarketerId !== '') path += `&subMarketerId=${subMarketerId}`;

    window.parent.location.href = path;
  },
};
</script>

<style scoped>

</style>
