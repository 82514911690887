<template>
  <div>
    <Banner :imageList="bannerImages" type="home" v-if="!!middleBanner"/>
    <ContentLoading v-else type="banner" />
    <Footer page="home" v-if="!hideCondition"/>
    <bottom-left-call />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import getBannerImage from '@/mixins/getBannerImage';

export default {
  name: 'HotelChain',
  data() {
    return {
    };
  },
  mixins: [getBannerImage],
  components: {
    Banner: () => import('@/components/content/Banner'),
    Footer: () => import('@/components/content/footer/FooterTheme0'),
    ContentLoading: () => import('@/components/atoms/ContentLoading'),
    BottomLeftCall: () => import('@/components/content/BottomLeftCall'),
  },
  computed: {
    ...mapGetters({
      destinationList: 'GET_DOMESTIC_DESTINATION',
      hotelList: 'GET_DOMESTIC_HOTELS',
      hotelAllInfo: 'GET_HOTEL_ALL_INFO',
      isLoading: 'GET_LOADING_STATE',
      lang: 'GET_LANGUAGE',
      hideCondition: 'GET_HIDE_CONDITION',
    }),
    query() {
      const route = this.$route;
      return route.query;
    },
    id() {
      const { query } = this;
      return query.id;
    },
  },
  watch: {
    query() {
      document.querySelector('.st-content').scrollTo(400, 400);
    },
  },
  async created() {
    this.$store.commit('SET_CURRENT_PAGE', 'hotel-chain');
    if (!this.hotelAllInfo.length) {
      await this.$store.dispatch('FETCH_ALL_HOTEL_INFO');
    }
    document.querySelector('.st-content').scrollTo(400, 400);
  },
};
</script>

<style scoped>

</style>
