<template>
  <div>
    <button class="btn btn-primary" @click="makeOrder">send Purchase</button>
  </div>
</template>

<script>

import dayjs from 'dayjs';

export default {
  methods: {
    async makeOrder() {
      const now = dayjs();
      const formattedDate = now.format('YYYY-MM-DD HH:mm:ss');
      const data = {
        transaction_id: formattedDate,
        value: 25.42,
        tax: 4.90,
        shipping: 5.99,
        currency: 'USD',
        coupon: 'SUMMER_SALE',
        items: [
        // If someone purchases more than one item,
        // you can add those items to the items array
          {
            item_id: `SKU_${formattedDate}`,
            item_name: 'Stan and Friends Tee',
            affiliation: 'Google Merchandise Store',
            coupon: 'SUMMER_FUN',
            discount: 2.22,
            index: 0,
            item_brand: 'Google',
            item_category: 'Apparel',
            item_category2: 'Adult',
            item_category3: 'Shirts',
            item_category4: 'Crew',
            item_category5: 'Short sleeve',
            item_list_id: 'related_products',
            item_list_name: 'Related Products',
            item_variant: 'green',
            location_id: 'ChIJIQBpAG2ahYAR_6128GcTUEo',
            price: 9.99,
            quantity: 1,
          },
        ],
      };
      window.gtag('event', 'purchase', data);
      console.log('emit purchase event');
    },
  },
};
</script>
