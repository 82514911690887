<template>
  <div>
    <div v-if="device != 'desktop'">
      <mobile-head-banner :stage="bookingStage" />
      <div class="mobail_part">
        <FlightDetail :flights="product.flights" />
      </div>
    </div>
    <div class="container p-0">
      <div
        class="new_booking_main_area dVacation-pack booking_mobile_part mt-3"
      >
        <div class="booking_details_three">
          <div class="price_details">
            <div class="price_details_heading" v-if="device === 'desktop'">
              <h3>{{ $t("booking.reservation-summery") }}</h3>
              {{ $route.params.flights }}
            </div>
            <div class="price_details_main_body">
              <div class="price_details_body">
                <div
                  class="product-page-flight p-1"
                  v-if="flightItems.length > 0"
                >
                  <FlightOnlyItem
                    v-for="(item, inx) in flightItems"
                    :key="inx"
                    :flight="item"
                    :categoryName="categoryName"
                    type="product"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="passenger_detail">
            <div>
              <div class="passenger_details_main_area booking_mobile_part mt-3">
                <div v-if="device == 'desktop'">
                  <div
                    class="passenger_box_one"
                    style="
                      background-image: url(/assets/img/passenger-box-banner-two.jpg);
                    "
                  >
                    <h2>
                      {{ $t("booking.passenger-details")
                      }}<span class="icon"><i class="fas fa-user"></i></span>
                    </h2>
                  </div>
                </div>
                <div class="container guest_details_body" v-else>
                  <h3>
                    {{ $t("booking.guest-details")
                    }}<span class="icon"><i class="fas fa-user"></i></span>
                  </h3>
                  <p>{{ $t("booking.guest-desc") }}</p>
                </div>
                <UserListFlight
                  :flight="flight"
                  :index="0"
                  @updatedUser="updatingUser"
                />
              </div>
            </div>
          </div>

          <payer-information @updatePayer="updatingPayer" />

          <div
            class="new_booking_main_area dVacation-pack booking_mobile_part mt-md-5"
          >
            <div class="booking_details_three">
              <div class="price_details">
                <div class="price_details_heading" v-if="device == 'desktop'">
                  <h3>{{ $t("booking.reservation-terms") }}</h3>
                </div>
                <div class="price_details_main_body">
                  <div class="price_details_body">
                    <div class="terms_box">
                      <div class="form-check">
                        <label class="form-check-label pr-5">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            value=""
                          />{{ $t("booking.i-agree-to") }}
                          <strong>{{ $t("booking.agree-desc") }}</strong>
                          {{ $t("booking.read-desc") }}
                          <strong>{{ $t("booking.read-desc-corona") }}</strong>
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label pr-5">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            value=""
                          />
                          {{ $t("booking.email-receive-agree") }}
                        </label>
                      </div>
                      <div class="form-check">
                        <label class="form-check-label pr-5">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            value=""
                          />
                          {{ $t("booking.telephone-provide-agree") }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="total_booking_price" v-if="device == 'desktop'">
                <div class="d-flex justify-content-between">
                  <button
                    class="btn-booking"
                    @click="BookingNow"
                    :disabled="disableBook"
                  >
                    {{ $t("search-result.book-now") }}
                  </button>
                  <div class="price_box">
                    <h3>
                      <span>:{{ $t("booking.total-price") }}</span
                      >{{ totalPrice }} $
                    </h3>
                  </div>
                  <div class="price_box">
                    <h3>
                      <span>:{{ $t("booking.total-to-price") }}</span
                      >{{ totalPrice }} $
                    </h3>
                  </div>
                </div>
              </div>
              <div class="total_payment" v-else>
                <div class="container">
                  <div class="d-flex justify-content-between">
                    <h4>
                      <span>{{ $t("booking.total-payment") }}</span
                      >{{ totalPrice }} $
                    </h4>
                    <button
                      class="btn"
                      @click="BookingNow"
                      :disabled="disableBook"
                    >
                      {{ $t("search-result.book-now") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="countDownModal"
      centered
      size="lg"
      :dir="lang == 'he' ? 'rtl' : 'ltr'"
      :no-close-on-backdrop="true"
      :ok-only="true"
    >
      <template #modal-header="">
        <div
          class="d-flex flex-grow-1 align-items-baseline"
          :class="[lang == 'he' ? 'text-right' : 'text-left']"
        >
          <h3 class="m-auto">{{ $t("booking.notice") }}</h3>
          <h5>{{ maxSec }} {{ $t("booking.sec") }}</h5>
        </div>
      </template>
      <template>
        <span :class="[lang == 'he' ? 'text-right' : 'text-left']">{{
          $t("booking.go-product-page")
        }}</span>
      </template>
      <template #modal-footer="{ ok }">
        <b-button size="lg" variant="success" @click="gotoProductPage(); ok()">
          {{ $t("booking.ok") }}
        </b-button>
      </template>
    </b-modal>
    <b-toast
      id="countDown"
      toaster="b-toaster-top-center"
      variant="warning"
      :autoHideDelay="maxSec * 1000"
      solid
      v-model="showCountDown"
      :classes="[lang == 'he' ? 'rtl' : 'ltr']"
    >
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-baseline">
          <h5>{{ maxSec }} {{ $t("booking.sec") }}</h5>
          <h3 class="m-auto">{{ $t("booking.notice") }}</h3>
        </div>
      </template>
      {{ $t("booking.go-product-page") }}
    </b-toast>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BToast, BModal, BButton } from 'bootstrap-vue';

export default {
  data() {
    return {
      query: null,
      bookingStage: 2,

      product: null,
      category: null,
      flightItems: [],
      categoryName: '',
      flight: null,
      userList: [],

      payerInfo: {
        firstNamePayer: '',
        lastNamePayer: '',
        phonePayer: '',
        emailPayer: '',
        idCardNumberPayer: '',
        creditCardExpirYear: '',
        creditCardExpirMonth: '',
        noOfPayment: '1',
      },

      totalPrice: 0,

      showCountDown: false,
      maxSec: 10,

      availableUsers: false,
      availablePayer: false,

      disableBook: false,
      timeID: -1,
    };
  },
  beforeMount() {
    this.query = this.$route.query;
  },
  components: {
    BToast,
    BModal,
    BButton,
    MobileHeadBanner: () => import('@/components/booking/atoms/MobileHeadBanner'),
    UserListFlight: () => import('@/components/booking/atoms/UserListFlight'),
    FlightDetail: () => import('@/components/productPage/FlightDetail'),
    PayerInformation: () => import('@/components/booking/atoms/PayerInformation'),
    FlightOnlyItem: () => import('@/components/searchResult/atoms/FlightOnlyItem'),
  },
  computed: {
    ...mapGetters({
      categoryState: 'GET_CURRENT_CATEGORY',
      // productState: 'GET_PRODUCT',
      bookingDataID: 'GET_BOOKING_DATA_ID',
      partnerId: 'GET_PARTNER_ID',
      device: 'GET_DEVICE',
      lang: 'GET_LANGUAGE',
    }),
  },
  methods: {
    async fetchData() {
      const localProduction = JSON.parse(
        window.localStorage.getItem('production'),
      );
      this.category = this.categoryState || localProduction.category;
      this.product = localProduction;
      if (this.product.flights.length > 0) {
        if (this.discounted) {
          this.flightItems = this.product.flights.filter(
            (item) => `${item.FlightDetail[0].FL_ID}${item.FlightDetail[1].FL_ID}`
              === this.$route.query.flights,
          );
        } else {
          this.flightItems = this.product.flights;
        }
      }
      this.flight = this.product.generationInfo;
    },

    updatingUser(updatedUser) {
      const user = this.userList.filter(
        (pUser) => pUser.id === updatedUser.id && pUser.groupID === updatedUser.groupID,
      )[0];
      if (user !== undefined) {
        Object.assign(user, updatedUser);
      } else {
        this.userList.push(updatedUser);
      }
    },
    updatingPayer(updatedPayer) {
      this.payerInfo = updatedPayer;
      this.availablePayer = updatedPayer.available;
    },
    formattingBookData() {
      const production = JSON.parse(window.localStorage.getItem('production'));
      let userID = 0;
      const userIdList = [];
      this.availableUsers = true;
      this.userList.forEach((user) => {
        userID += 1;
        user.id = String(userID);
        userIdList.push(String(userID));
        if (!user.validate) this.availableUsers = false;
      });
      const outwardFlight = production.flights[0].FlightDetail[0],
        inwardFlight = production.flights[0].FlightDetail[1];

      const paxList = [];
      this.userList.forEach((user) => {
        const clone = { ...user };
        delete clone.groupID;
        paxList.push(clone);
      });

      const bookInfo = {
        partnerId: this.partnerId,
        packageId: production.packId,
        hotel_shift_1: production.hotel_shift_1,
        duration: production.duration_1,
        discountPercent: production.discountPercent || null,
        price: this.totalPrice,
        currency: 'US',
        paxList,
        outwardFlight: {
          flightId: outwardFlight.FL_ID,
          departureCityCode: outwardFlight.FL_From_Air_Port,
          departureDate: outwardFlight.FL_Date,
          departureTime: outwardFlight.FL_Dep_Hour,
          arrivalCity: outwardFlight.FL_To_Air_Port,
          class: this.flightItems[0].Class1,
          paxIds: userIdList,
        },
        inwardFlight: {
          flightId: inwardFlight.FL_ID,
          departureCityCode: inwardFlight.FL_From_Air_Port,
          departureDate: inwardFlight.FL_Date,
          departureTime: inwardFlight.FL_Dep_Hour,
          arrivalCity: inwardFlight.FL_To_Air_Port,
          class: this.flightItems[0].Class2,
          paxIds: userIdList,
        },
        payer: {
          name: `${this.payerInfo.firstNamePayer} ${this.payerInfo.lastNamePayer}`,
          email: this.payerInfo.emailPayer,
          mobile: this.payerInfo.phonePayer,
          identificationNumber: this.payerInfo.idCardNumberPayer,
          creditCardExpirMonth: this.payerInfo.creditCardExpirMonth,
          creditCardExpirYear: this.payerInfo.creditCardExpirYear,
          noOfPayment: Number(this.payerInfo.noOfPayment),
        },
      };
      const data = {
        data: bookInfo,
        sendingStage: 'update',
      };
      return data;
    },
    async sendBookingInformation() {
      const data = this.formattingBookData();
      const response = await this.$store.dispatch('SEND_BOOKING_DATA', data);
      return response;
    },
    async BookingNow() {
      this.disableBook = true;
      let response = { success: true };
      const retry = window.localStorage.getItem('retryState');
      let saveBookData = true;
      if (retry !== 'null') {
        const oldState = JSON.parse(retry);
        saveBookData = !oldState.readOnly;
        this.availableUsers = oldState.readOnly;
      }
      if (saveBookData) {
        response = await this.sendBookingInformation();
      } else {
        response.id = window.localStorage.getItem('BookingDataID');
      }
      if (response.success) {
        if (this.availablePayer && this.availableUsers) {
          const { host } = window.location;
          const info = {
            email: this.payerInfo.emailPayer,
            mobile: this.payerInfo.phonePayer,
            paymentName: `${this.payerInfo.firstNamePayer} ${this.payerInfo.lastNamePayer}`,
            creditCardExpirMonth: this.payerInfo.creditCardExpirMonth,
            creditCardExpirYear: this.payerInfo.creditCardExpirYear,
            identificationNumber: this.payerInfo.idCardNumberPayer,
            noOfPayment: Number(this.payerInfo.noOfPayment),
            successUrl: `https://${host}/redirect-verified?lang=${this.lang}`,
            // "successUrl":"http://127.0.0.1:8080/redirect-verified",
            failureUrl: `https://${host}/redirect-verified1?lang=${this.lang}`,
            cssUrl: `https://${host}//assets/css/styleVerify.css`,
          };
          const res = await this.$store.dispatch('GET_PAYMENT_URL', {
            payerInfo: info,
            bookID: response.id,
          });
          if (res.success) {
            if (!res.error) {
              // window.removeEventListener("beforeunload", this.ClosingWindow);
              // let verifyWindow = window.open(res.url.paymentUrl, "_self", "");
              // verifyWindow.focus();
              this.$store.dispatch('UPDATE_VERIFYING_URL', res.url.paymentUrl);
              this.$router.push({
                path: '/booking/verifying-card',
                query: { price: this.totalPrice, lang: this.lang },
              });
            } else if (res.error.retryAllowed) {
              const message = res.error.retryPaymentMethodOnly
                ? 'please fill the payer information correctly and try it again. thanks.'
                : 'please fill the pax list and payer information correctly and try it again. thanks.';

              this.$bvToast.toast(message, {
                title: res.error.code,
                autoHideDelay: 10000,
                appendToast: true,
                variant: 'danger',
                toaster: 'b-toaster-top-right',
                noCloseButton: true,
              });
              this.disableBook = false;
            } else {
              const message = 'Server Internal Error. please try it from first again. thanks.';
              this.$bvToast.toast(message, {
                title: res.error.code,
                autoHideDelay: 5000,
                appendToast: true,
                variant: 'danger',
                toaster: 'b-toaster-top-right',
                noCloseButton: true,
              });
              const oneself = this;
              setTimeout(() => {
                oneself.$router.push({ path: '/' });
              }, 5000);
            }
          } else if (res.error.response.status === 400) {
            const message = 'please fill the payer information correctly and try it again. thanks.';
            this.$bvToast.toast(message, {
              title: res.error.response.title,
              autoHideDelay: 10000,
              appendToast: true,
              variant: 'danger',
              toaster: 'b-toaster-top-right',
              noCloseButton: true,
            });
            this.disableBook = false;
          } else {
            const message = 'Server Internal Error. please try it from first again. thanks.';
            this.$bvToast.toast(message, {
              title: res.error.code,
              autoHideDelay: 5000,
              appendToast: true,
              variant: 'danger',
              toaster: 'b-toaster-top-right',
              noCloseButton: true,
            });
            const oneself = this;
            setTimeout(() => {
              oneself.$router.push({ path: '/' });
            }, 5000);
          }
        } else {
          this.$bvToast.toast(
            'Please fill the user informations and payer informations correctly. Thanks',
            {
              title: 'Error for informations',
              autoHideDelay: 10000,
              appendToast: true,
              variant: 'danger',
              toaster: 'b-toaster-top-right',
              noCloseButton: true,
            },
          );
          this.disableBook = false;
        }
      } else {
        this.$bvToast.toast(
          'Your booking ID is not available now, so please try booking from previous page again. It will go to previous page 5s later automatically. thanks.',
          {
            title: 'Error for informations',
            autoHideDelay: 10000,
            appendToast: true,
            variant: 'danger',
            toaster: 'b-toaster-top-right',
            noCloseButton: true,
          },
        );
        this.disableBook = false;
      }
    },
    async ClosingWindow() {
      const data = this.formattingBookData();
      const bookingDataID = this.bookingDataID || window.localStorage.getItem('BookingDataID');
      this.$store.dispatch('SEND_CLOSING_NOTICE', {
        bookDataID: bookingDataID,
        data: JSON.stringify(data.data),
        withData: true,
      });
    },
    gotoProductPage() {
      clearInterval(this.timeID);
      this.$bvModal.hide('countDownModal');
      this.$store.dispatch('REDIRECT_TO_PRODUCT');
      this.$router.go(-1);
    },
  },
  async created() {
    this.disableBook = false;
    this.$emit('setBookingStage', 2);
    await this.fetchData();
    this.totalPrice = this.product.priceTotal;
    this.categoryName = this.category.code;

    const retry = window.localStorage.getItem('retryState'),
      dataBook = JSON.parse(window.localStorage.getItem('BookingDetailInfo')),
      { payer } = dataBook.data,
      { sendingStage } = dataBook;

    if (retry !== 'null' && sendingStage !== 'init') {
      if (payer.name.length > 0) {
        const name = payer.name.split(' ');
        this.payerInfo.firstNamePayer = name[0];
        this.payerInfo.lastNamePayer = name[1];
      }
      this.payerInfo.phonePayer = payer.mobile;
      this.payerInfo.emailPayer = payer.email;
      this.payerInfo.idCardNumberPayer = payer.identificationNumber;
      this.payerInfo.creditCardExpirYear = payer.creditCardExpirYear;
      this.payerInfo.creditCardExpirMonth = payer.creditCardExpirMonth;
      this.payerInfo.noOfPayment = Number(payer.noOfPayment);
    }
    // window.addEventListener('beforeunload', this.ClosingWindow);
  },
  mounted() {
    const retry = JSON.parse(window.localStorage.getItem('retryState')),
      dataBook = JSON.parse(window.localStorage.getItem('BookingDetailInfo')),
      // eslint-disable-next-line no-unused-vars
      { payer } = dataBook.data,
      { sendingStage } = dataBook;
    if (retry == null && sendingStage !== 'init') {
      // this.$bvToast.show("countDown");
      this.$bvModal.show('countDownModal');
      const oneself = this;
      this.timeID = setInterval(() => {
        oneself.maxSec -= 1;
        if (oneself.maxSec === 0) {
          oneself.gotoProductPage();
        }
      }, 1000);
    }

    dataBook.sendingStage = 'update';
    window.localStorage.setItem('BookingDetailInfo', JSON.stringify(dataBook));
  },
  destroyed() {
    // window.removeEventListener('beforeunload', this.ClosingWindow);
  },
};
</script>

<style>
.modal .modal-dialog {
  display: flex;
  font-family: "ploni", "Poppins", sans-serif;
  font-weight: 500;
}
.modal:before {
  display: none;
}
#countDownModal.modal .modal-body {
  display: flex;
}
</style>

<style scoped>
#countDown.ltr {
  direction: ltr;
}
#countDown.rtl {
  direction: rtl;
}
#countDown h5 {
  padding: 15px 5px;
  font-size: 15px;
}
#countDown h3 {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}
.price_details_heading {
  padding: 20px 30px;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    180deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(1, 98, 172, 1) 0%,
    rgba(38, 135, 209, 1) 100%
  );
}

.price_details_main_body {
  padding: 30px 30px;
  border-left: 1px solid #d3d3d3;
  border-right: 1px solid #d3d3d3;
  border-bottom: 1px solid #d3d3d3;
}

.price_details_heading h3 {
  color: #ffffff;
  font-size: 24px;
  margin: 0 0;
  padding: 0 0;
  text-transform: uppercase;
}

.price_details_body h4 {
  color: #000;
  text-transform: capitalize;
  font-size: 25px;
  margin-bottom: 2px;
}

.rating {
  height: auto;
  color: black;
  background: transparent;
  border: none;
}

.passenger_details_main_area .passenger_box_one {
  height: 100px;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 5px;
  position: relative;
  margin-bottom: 15px;
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 30px 20px;
}

.passenger_details_main_area .passenger_box_one h2 {
  margin: 0 0 0;
  padding: 0 0 0;
  color: #fff;
  position: relative;
  font-size: 30px;
  text-transform: capitalize;
  padding-right: 45px;
  direction: ltr;
}

.passenger_details_main_area {
  color: #fff;
}
.passenger_details_main_area .passenger_box_one h2 .icon {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  font-size: 30px;
}

.passenger_box_two {
  border-radius: 5px;
}

.terms_box .form-check {
  margin-bottom: 10px;
}

.terms_box .form-check label {
  font-size: 15px;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-right: -1.25rem;
}

.form-check label strong {
  font-weight: 400;
  color: #0061ab;
}

.total_booking_price {
  background: #0161ab;
  padding: 30px 30px;
  box-sizing: border-box;
}

.total_booking_price .btn-booking {
  background: #ffffff;
  color: #0060aa;
  font-size: 16px;
  text-transform: uppercase;
  padding: 12px 24px;
  border: 1px solid #ffffff;
  border-radius: 30px;
  transition: 0.5s;
  cursor: pointer;
  font-weight: 500;
}

.total_booking_price .price_box h3 {
  margin: 0 0 0;
  padding: 0 0 0;
  color: #fff;
  font-size: 30px;
}

.total_booking_price .price_box h3 span {
  display: block;
  font-size: 15px;
  text-align: left;
  margin-bottom: 2px;
}

.guest_details_body h3 {
  padding: 0 0 7px;
  font-size: 18px;
  font-weight: 600;
  color: #000;
  text-transform: capitalize;
  border-bottom: 2px solid #bfbfbf;
  margin-bottom: 10px;
  position: relative;
  padding-right: 25px;
}
.guest_details_body h3 .icon {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
}
.guest_details_body p {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 8px;
  color: #212529;
}

@media (min-width: 992px) {
  .price_details_main_body {
    padding: 30px 1px;
  }
}

@media (max-width: 479px) {
  .price_details_main_body {
    padding: 0px;
  }

  .terms_box .form-check label {
    font-size: 12px;
    color: #212529;
  }
  .form-check {
    margin-bottom: 8px;
  }

  .form-check label strong {
    color: #212529;
    font-weight: bolder;
  }

  .total_payment {
    padding: 20px 0px 0px;
    overflow: hidden;
    box-shadow: 0px -2px 2px rgba(221, 221, 221, 0.5);
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background: #fff;
    z-index: 91;
  }
  .total_payment .btn {
    background: #0061ab;
    color: #fff;
    text-transform: lowercase;
    padding: 0 14px;
    line-height: 0;
    height: 34px;
    font-size: 15px;
    font-weight: 500;
    margin-top: 10px;
  }
  .total_payment h4 {
    text-align: left;
  }
  .total_payment h4 span {
    font-size: 12px;
    font-weight: 400;
    display: block;
    margin-bottom: 5px;
  }
}
</style>
