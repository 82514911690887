import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      imagesData: 'GET_BANNER_IMAGES',
      marketerAgencyContent: 'GET_MARKETER_AGENCY_CONTENT',
      lang: 'GET_LANGUAGE',
      langCode: 'GET_LANGUAGECODE',
    }),
    showTopSlider() {
      return !(this.marketerAgencyContent?.marketerSpecificContents?.images?.homeTopSlider?.hide ?? false);
    },
    bannerImages() {
      let images = [];
      if (this.showTopSlider) {
        if (this.marketerAgencyContent?.marketerSpecificContents?.images?.homeTopSlider?.imageWithHrefList?.length > 0) {
          images = this.marketerAgencyContent?.marketerSpecificContents?.images?.homeTopSlider?.imageWithHrefList?.map((img) => img.image);
        } else if (this.imagesData?.imagesByCode?.DomesticTopSlider?.length > 0) {
          images = this.imagesData?.imagesByCode?.DomesticTopSlider?.map((d) => d.image);
        } else {
          images = [
            'assets/img/home-banner-image-02.jpg',
            'assets/img/home-banner-image-03.jpg',
            'assets/img/home-banner-image-01.jpg',
          ];
        }
      }

      // for testing
      // const { host } = window.location;
      // if (host.includes('localhost')) {
      //   images = [
      //     'assets/img/home-banner-image-02.jpg',
      //     'assets/img/home-banner-image-03.jpg',
      //     'assets/img/home-banner-image-01.jpg',
      //   ];
      // }
      return images;
    },
    showMiddleBanner() {
      return !(this.marketerAgencyContent?.marketerSpecificContents?.images?.homeSecondBanner?.hide ?? false);
    },
    middleBanner() {
      let images = [];
      if (this.showMiddleBanner) {
        if (this.marketerAgencyContent?.marketerSpecificContents?.images?.homeSecondBanner?.imageWithHrefList?.length > 0) {
          images = this.marketerAgencyContent?.marketerSpecificContents?.images?.homeSecondBanner?.imageWithHrefList.map((img) => img.image);
        } else {
          images = this.imagesData?.imagesByCode?.DomesticSecondBanner?.map((d) => d.image);
        }
      }
      return images;
    },
    middleBannerHref() {
      return this.marketerAgencyContent?.marketerSpecificContents?.images?.homeSecondBanner?.imageWithHrefList?.[0]?.href
        || this.imagesData?.imagesByCode?.DomesticSecondBanner?.[0]?.href || '';
    },
    showTopBanner() {
      return !(this.marketerAgencyContent?.marketerSpecificContents?.images?.homeTopBanner?.hide ?? false);
    },
    topBanner() {
      let images = [];
      if (this.showTopBanner) {
        if (this.marketerAgencyContent?.marketerSpecificContents?.images?.homeTopBanner?.imageWithHrefList?.length > 0) {
          images = this.marketerAgencyContent?.marketerSpecificContents?.images?.homeTopBanner?.imageWithHrefList;
        } else {
          images = this.imagesData?.imagesByCode?.DomesticTopBanner;
        }
      }
      return images;
    },
    secondBannerHeaderHome() {
      const item = this.imagesData?.hrefsByItemId?.SecondBannerHeader?.caption_Translations.find((el) => el.languageCode === this.langCode);
      return item?.content ?? this.$t('home.special-offers');
    },
  },
  created() {
    if (!this.imagesData?.imagesByCode?.DomesticTopSlider) {
      this.$store.dispatch('FETCH_BANNER_IMAGES', { page: 'homeInDmstc' });
    }
  },
};
