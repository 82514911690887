<template>
  <div>
    <Banner :imageList="bannerImages" type="home" v-if="!!middleBanner"/>
    <ContentLoading v-else type="banner" />
    <search-tab />
    <h1 v-if="cityId != ''" class="text-center mt-5 mb-0 mx-1" :dir="lang === 'en'?'ltr':''" style="font-weight: bold;">{{$t("hotel-page.hotels-in")}}{{destinationName}} </h1>
    <h1 v-if="chainName != ''" class="text-center mt-5 mb-0 mx-1" :dir="lang === 'en'?'ltr':''" style="font-weight: bold;">{{$t("hotel-page.hotels-in-network")}}{{chainName}} </h1>
    <div class="col-12 pt-5" v-if="isLoading">
      <ContentLoading />
    </div>
    <div class="col-12 p-4" v-else>
      <!-- <button @click="csvDown">csv down</button> -->
      <CardGroup :items="hotels" page="hotels"/>
    </div>

    <Footer page="home" v-if="!hideCondition"/>
    <bottom-left-call />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import getBannerImage from '@/mixins/getBannerImage';

export default {
  name: 'HotelsPage',
  data() {
    return {
    };
  },
  metaInfo() {
    if (this.cityId !== '') {
      return {
        title: `בתי מלון ב ${this.destinationName} | השטיח המעופף`,
        meta: [
          { name: 'description', content: `השטיח המעופף מבצעים בלעדיים ל ${this.destinationName}. עד 30% הנחה למלונות ${this.destinationName}` },
          { property: 'og:title', content: `בתי מלון ב ${this.destinationName}` },
        ],
      };
    } else {
      return {
        title: `בתי מלון ב ${this.destinationName} | השטיח המעופף`,
        meta: [
          { name: 'description', content: `השטיח המעופף, חברת התיירות הגדולה בישראל במחירים בלעדיים לרשת ${this.chainName}. הכנסו עכשיו לאתר, רשת מלונות ${this.chainName}  במחירים מצויינים` },
          { property: 'og:title', content: `בתי מלון ברשת ${this.chainName}` },
        ],
      };
    }
  },
  async mounted() {
    window.localStorage.setItem('landing-url', window.location.search);
    this.$i18n.locale = 'he';
    this.$store.dispatch('UPDATE_LANGUAGE', this.$i18n.locale);
    window.localStorage.setItem('language', this.$i18n.locale);
    // this.addGoogleAnalytice();
    // this.setCookie();
  },
  mixins: [getBannerImage],
  components: {
    Banner: () => import('@/components/content/Banner'),
    SearchTab: () => import('@/components/search/searchTabs/SearchTabsTheme0'),
    Footer: () => import('@/components/content/footer/FooterTheme0'),
    ContentLoading: () => import('@/components/atoms/ContentLoading'),
    CardGroup: () => import('@/components/hotelPages/CardColumnGroup'),
    BottomLeftCall: () => import('@/components/content/BottomLeftCall'),
  },
  computed: {
    ...mapGetters({
      destinationList: 'GET_DOMESTIC_DESTINATION',
      chainList: 'GET_HOTEL_CHAIN_TABLE',
      hotelList: 'GET_DOMESTIC_HOTELS',
      hotelAllInfo: 'GET_HOTEL_ALL_INFO',
      isLoading: 'GET_LOADING_STATE',
      lang: 'GET_LANGUAGE',
      hideCondition: 'GET_HIDE_CONDITION',
    }),
    query() {
      const route = this.$route;
      return route.query;
    },
    cityId() {
      const { city } = this.query;
      if (!city) return '';
      return city;
    },
    chainId() {
      const { chain } = this.query;
      if (!chain) return '';
      return chain;
    },
    destinationName() {
      const { cityId, destinationList } = this;
      if (!cityId || destinationList.length === 0) return '';
      return this.destinationList.find((elem) => elem.code === cityId).name;
    },
    chainName() {
      const { chainId, chainList } = this;
      if (!chainId || chainList.length === 0) return '';
      return this.chainList.find((elem) => elem.id === chainId).name;
    },
    hotels() {
      const { cityId, chainId, hotelAllInfo } = this;
      if (!hotelAllInfo) return [];
      if (cityId) {
        const hotels = hotelAllInfo.filter((elm) => elm.hotelConversion?.mainCityCode === cityId && elm.hotelConversion?.hotelExternalCode).map((elm) => ({
          src: elm.mainImageUrls[0] || elm.galleryImageUrls[0] || elm.galleryImageUrls[0],
          name: elm.hotelConversion.hotelName,
          description: elm.hotelDescription,
          destinationName: this.destinationName,
          exCode: elm.hotelConversion.hotelExternalCode,
          city: this.cityId,
        }));
        return hotels;
      } else if (chainId) {
        const hotels = hotelAllInfo.filter((elm) => elm.hotelConversion?.hotelChainId === chainId && elm.hotelConversion?.hotelExternalCode).map((elm) => ({
          src: elm.mainImageUrls[0] || elm.galleryImageUrls[0] || elm.galleryImageUrls[0],
          name: elm.hotelConversion.hotelName,
          description: elm.hotelDescription,
          destinationName: this.destinationName,
          exCode: elm.hotelConversion.hotelExternalCode,
          chain: this.chainId,
          city: elm.hotelConversion ? elm.hotelConversion.mainCityCode : '',
        }));
        return hotels;
      } else {
        return [];
      }
    },
    allHotels() {
      const { cityId, hotelAllInfo } = this;
      if (!hotelAllInfo) return [];
      if (!cityId) return [];

      const hotels = hotelAllInfo.filter((elm) => elm.hotelConversion?.hotelExternalCode).map((elm) => ({
        src: elm.mainImageUrls[0] || elm.galleryImageUrls[0] || elm.galleryImageUrls[0],
        name: elm.hotelConversion.hotelName,
        description: elm.hotelDescription,
        destinationName: this.destinationName,
        exCode: elm.hotelConversion.hotelExternalCode,
        city: this.cityId,
      }));
      return hotels;
    },
    csvContent() {
      const { allHotels } = this;
      if (!allHotels) return null;
      const rows = [];
      allHotels.forEach((item) => {
        rows.push([`${item.name} - ${item.destinationName}`, `/hotel-info/?city=${item.city}&hotelCode=${item.exCode}`]);
      });
      return `data:text/csv;charset=utf-8, ${rows.map((e) => e.join(',')).join('\n')}`;
    },
  },
  watch: {
    query() {
      setTimeout(() => { document.querySelector('.st-content').scrollTo(400, 400); }, 500);
    },
  },
  methods: {
    csvDown() {
      const encode = encodeURI(this.csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', encode);
      link.setAttribute('download', 'hotels.csv');
      document.body.appendChild(link);

      link.click();
    },
  },
  async created() {
    this.$store.commit('SET_CURRENT_PAGE', 'hotels-page');
    if (!this.hotelAllInfo.length) {
      await this.$store.dispatch('FETCH_ALL_HOTEL_INFO');
    }
    document.querySelector('.st-content').scrollTo(400, 400);
  },
};
</script>

<style scoped>

</style>
