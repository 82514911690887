<template>
  <div v-if="isFCLAgent">
    <Banner :banner="bannerImages"/>
    <div class="my-5 mx-1 hotelplusflight">
      <div class="external-price-container">
        <b-button variant="primary" class="mb-2" @click="showFilterModal">{{ $t('search-result.filter')}}</b-button>

        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          :first-text="$t('pending-info.first')"
          :prev-text="$t('pending-info.prev')"
          :next-text="$t('pending-info.next')"
          :last-text="$t('pending-info.last')"
          class="p-0"
        ></b-pagination>

        <b-table responsive striped sticky-header="600px" no-border-collapse outlined table-variant="light" head-variant="light"
        :per-page="perPage"
        :current-page="currentPage"
        :tbody-tr-class="rowColorClassByTag"
        :busy.sync="isBusy"
        :items="items" :fields="fields">

          <template #cell(tag)="row">
            <b-form-select :value="row.item.tag" @change="(value) => changeTag(value, row.item.transId)" :options="options"></b-form-select>
          </template>

          <template #cell(hotelOrderNum)="row">
            <span v-if="row.item.isAtlantis && row.item.isSucceeded">{{ $t('pending-info.confirmed') }}</span>
            <span v-if="!row.item.isAtlantis && row.item.isSucceeded">{{ row.item.hotelOrderNum }}</span>
            <span v-else>{{ $t('pending-info.unconfirmed') }}</span>
          </template>

          <template #cell(status)="row">
            <b-badge :variant="row.item.isSucceeded ? 'success' : 'danger'">
              <span v-if="row.item.isSucceeded">OK</span>
              <span v-else>RQ</span>
            </b-badge>
          </template>

          <template #cell(roomKind)="row">
            <!-- <div v-b-tooltip.hover :title="`Adults:${row.item.adult} Children:${row.item.children} Baby:${row.item.infants}`" dir="ltr"> -->
              <!-- <span style="white-space: nowrap;">
                {{row.item.roomKind}} <b-icon-question-circle-fill variant="info"/>
              </span> -->
              <p v-for="(room, inx) in row.item.rooms" :key="inx" v-b-tooltip.hover :title="room.roomClassName" dir="ltr" class="m-0" style="white-space: nowrap;">
                {{`${room.roomCompositionTypeCode}(A:${room.adults},C:${room.children},B:${room.infant})`}}
                <b-icon-question-circle-fill variant="info"/>
              </p>
              <span>NumOfRms:{{row.item.roomsNumber}}</span>
            <!-- </div> -->
          </template>

          <template #cell(first_action)="row">
            <b-button size="sm" @click="atlantisAction(row.item.transId, row.item.index)" class="mr-2"
              v-if="row.item.isAtlantis">
               <span>{{ $t('pending-info.confirm')}}</span>
            </b-button>
            <b-button size="sm" @click="firstAction(row.item.transId, row.item.index)" class="mr-2"
              v-else
              :disabled="row.item.diffMinute < 0 || row.item.isSucceeded">
               <span v-if="row.item.diffMinute < 0 || row.item.isSucceeded">Quick</span>
               <span v-else>{{ `Quick ${row.item.diffMinute}` }}</span>
            </b-button>
          </template>

          <template #cell(second_action)="row">
            <b-button size="sm" @click="secondAction(row.item.transId, row.item.index)" class="mr-2"
              :disabled="row.item.isAtlantis || row.item.diffMinute >= 0 || row.item.isSucceeded">
              External
            </b-button>
          </template>

          <template #cell(third_action)="row">
            <b-button size="sm" @click="thirdAction(row.item.transId, !row.item.isHiddenRQ)" class="mr-2"
              :disabled="row.item.isSucceeded">
               {{row.item.isHiddenRQ ? `Restore` : `Delete`}}
            </b-button>
          </template>

          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>{{$t('pending-info.loading')}}</strong>
            </div>
          </template>

        </b-table>
      </div>
    </div>
    <Footer page="home" v-if="!hideCondition"/>

    <b-modal id="resultModal" centered size="lg" :dir="lang == 'he' ? 'rtl' : 'ltr'" :no-close-on-backdrop="true" :ok-only="true">
      <template #modal-header="{}">
        <div class="d-flex flex-grow-1 align-items-baseline" :class="[lang == 'he' ? 'text-right' : 'text-left']">
          <h3 class="m-auto">{{ resultTitle }}</h3>
        </div>
      </template>
      <template>
        <!-- <span :class="[lang == 'he' ? 'text-right' : 'text-left']">{{ $t("booking.go-product-page") }}</span> -->
        <b-alert variant="success" :show="resultState === 'success'">
          <p dir="rtl">{{resultMessage}}</p>
          <p><span>PNR:</span> <span>{{resultPNR}}</span></p>
          <p><span>Hotel Confirmation Number:</span> <span>{{resultHotelOrderNumber}}</span></p>
        </b-alert>

        <div class="please_mall text-center" v-if="voucherLink">
          <div class="icon_box">
            <b-link :href="voucherLink" @click.prevent="downloadTickets(voucherLink, 'HotelVoucher')">
              <img src="/assets/img/after-booking-icon18.png" alt="after-booking-icon"/>
            </b-link>
          </div>
          <h5>{{ $t('booking.hotel-voucher') }}</h5>
        </div>

        <b-alert variant="danger" :show="resultState === 'error'">
          <p>{{resultMessage}}</p>
        </b-alert>
      </template>
      <template #modal-footer="{ ok }">
        <b-button size="lg" variant="success" @click="ok();">
          {{ $t("booking.ok") }}
        </b-button>
      </template>
    </b-modal>

    <b-modal id="filterModal" centered size="lg" :dir="lang == 'he' ? 'rtl' : 'ltr'" :no-close-on-backdrop="true" :ok-only="true">
      <template #modal-header="{}">
        <div class="d-flex flex-grow-1 align-items-baseline" :class="[lang == 'he' ? 'text-right' : 'text-left']">
          <h3 class="m-auto">{{$t('search-result.filter')}}</h3>
        </div>
      </template>
      <template>
        <b-form-group label="PNR:" label-for="input-3">
          <b-form-input
            v-model="filterPnr"
            type="text"
            class="agent-amount"
          ></b-form-input>
        </b-form-group>
        <b-form-group :label="`${$t('booking.agent-list')}:`">
          <v-select
            v-model="filterAgent"
            :reduce="FilterAgent => FilterAgent.value"
            :options="agentList"
            dir="rtl"
            :clearable="false"
            :searchable="true"
          >
          </v-select>
        </b-form-group>
        <b-form-group :label="`${$t('pending-info.clerk-name')}:`">
          <v-select
            v-model="filterClerkName"
            :reduce="name => name.value"
            :options="clerkNameList"
            dir="rtl"
            :clearable="false"
            :searchable="true"
          >
          </v-select>
        </b-form-group>
        <b-form-group :label="`${$t('pending-info.tag-state')}:`">
          <b-form-select
            v-model="filterTag"
            :options="filterOptions"
          >
          </b-form-select>
        </b-form-group>
        <b-form-group :label="`${$t('pending-info.show-flag')}:`">
          <b-form-checkbox
            v-model="filterShowDelete"
            value="accepted"
            unchecked-value="not_accepted"
          >
          </b-form-checkbox>
        </b-form-group>
      </template>
      <template #modal-footer="{ ok }">
        <b-button variant="success" @click="applyFilter(); ok();">
          {{ $t("booking.ok") }}
        </b-button>
        <b-button variant="danger" @click="ok();">
          {{ $t("login.cancel") }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BTable, BBadge, BModal, BAlert, BButton, BFormGroup, BFormInput, BFormSelect, BFormCheckbox,
  BIconQuestionCircleFill, BLink,
  BPagination, BSpinner } from 'bootstrap-vue';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import dayjs from 'dayjs';
import { toBase64 } from '@/utils/base64Util';
import getProductBanner from '@/mixins/getProductBanner';

const tagOptions = ['active',
  'notActive',
  'noPaid',
  'paidPartly',
  'canceled'];

export default {
  name: 'PendingInfoPage',
  mixins: [getProductBanner],
  data() {
    return {
      fields: [
        {
          key: 'tag',
          label: 'Option',
        },
        {
          key: 'pnr',
          label: 'PNR',
        },
        {
          key: 'agencyName',
          label: 'Agency Name',
        },
        {
          key: 'hotelOrderNum',
          label: 'Hotel Confirmation Number',
        },
        {
          key: 'destination',
          label: 'Destination',
        },
        {
          key: 'status',
          label: 'Status',
        },
        {
          key: 'client',
          label: 'Client Name',
        },
        {
          key: 'clerkName',
          label: 'Agent Name',
        },
        {
          key: 'bookDate',
          label: 'Booking Date',
        },
        {
          key: 'checkinDate',
          label: 'Checkin Date',
        },
        {
          key: 'hotelName',
          label: 'Hotel Name',
        },
        {
          key: 'roomKind',
          label: 'Room Kind',
        },
        {
          key: 'basisCode',
          label: 'Basis',
        },
        // {
        //   key: 'roomClassName',
        //   label: 'Room Occupancy',
        // },
        // {
        //   key: 'adult',
        //   label: 'Adult',
        // },
        // {
        //   key: 'children',
        //   label: 'Children',
        // },
        // {
        //   key: 'infants',
        //   label: 'Babies',
        // },
        {
          key: 'netPrice',
          label: 'Net Price',
        },
        {
          key: 'finalPrice',
          label: 'Full Price',
        },
        {
          key: 'totalPaid',
          label: 'Total Paid',
        },
        {
          key: 'paidToFc',
          label: 'Total Debit To FC',
        },
        {
          key: 'first_action',
          label: 'Quick Booking',
        },
        {
          key: 'second_action',
          label: 'External Booking',
        },
        {
          key: 'Third_action',
          label: 'Delete',
        },
      ],
      delayMinute: 13,
      items: [],
      refreshIntervalId: null,
      currentIndex: null,

      resultTitle: '',
      resultMessage: '',
      resultState: 'error',
      resultPNR: '',
      resultHotelOrderNumber: '',
      voucherLink: '',

      filterAgent: '',
      filterPnr: '',
      filterTag: '',
      filterShowDelete: 'not_accepted',
      filterClerkName: '',

      perPage: 5,
      currentPage: 1,
    };
  },
  components: {
    BTable,
    BBadge,
    BModal,
    BAlert,
    BButton,
    vSelect,
    BFormGroup,
    BFormInput,
    BIconQuestionCircleFill,
    BPagination,
    BFormSelect,
    BFormCheckbox,
    BSpinner,
    BLink,
    Footer: () => import('@/components/content/footer/FooterTheme0'),
    Banner: () => import('@/components/productPage/productBanner/ProductBannerTheme0'),
  },
  computed: {
    ...mapGetters({
      isLoading: 'GET_LOADING_STATE',
      lang: 'GET_LANGUAGE',
      device: 'GET_DEVICE',
      allAgentList: 'GET_ALL_AGENT_LIST',
      isFCLAgent: 'IS_FCL_ODYSSEY_AGENT_CODE',
      allPendingInfo: 'GET_PENDING_BOOKING_INFO',
      newTokenPriceInfo: 'GET_NEW_PRICE_INFO',
      finalizeInfo: 'GET_FINALIZE_INFO',
      atlantisFinalizeInfo: 'GET_ATLANTIS_FINALIZE_INFO',
      isBusy: 'GET_TABLE_LOADING_STATE',
      hideCondition: 'GET_HIDE_CONDITION',
    }),
    agentList() {
      const list = this.allAgentList;
      if (list.length === 0) return [];
      const returnList = list.map((elm, inx) => ({ id: inx + 1, value: elm.agt_Code, label: `${elm.agt_Code} - ${elm.agt_Name}` }));
      returnList.unshift({ id: 0, value: '', label: this.$t('pending-info.all') });
      return returnList;
    },
    clerkNameList() {
      const list = ['rinay', 'siryaa', 'kinerets', 'innaz'].map((clerk, inx) => ({ id: inx + 1, value: clerk, label: clerk }));
      list.unshift({ id: 0, value: '', label: this.$t('pending-info.all') });
      return list;
    },
    query() {
      const route = this.$route;
      return route.query;
    },
    bookingTransId() {
      const { bookingTransId } = this.query;
      if (!bookingTransId) return '';
      return bookingTransId;
    },
    rows() {
      return this.items.length;
    },
    options() {
      return tagOptions.map((tag) => ({ value: tag, text: this.$t(`pending-info.${tag}`) }));
    },
    filterOptions() {
      const op = tagOptions.map((tag) => ({ value: tag, text: this.$t(`pending-info.${tag}`) }));
      op.unshift({ value: '', text: this.$t('pending-info.all') });
      return op;
    },
  },
  watch: {
    allPendingInfo() {
      this.getItems();
    },
    finalizeInfo() {
      this.checkFinalResult();
    },
    atlantisFinalizeInfo() {
      this.checkAtlantisFinalResult();
    },
  },
  created() {
    if (!this.isFCLAgent) {
      this.$router.push(`/?lang=${this.lang}`);
    } else {
      this.$store.commit('SET_CURRENT_PAGE', 'pending-info');
      this.$store.dispatch('FETCH_ALL_AGENT_LIST');
    }
  },
  async mounted() {
    await this.$store.dispatch('SOCKET_CONNECT', this);
    this.getItems();
    this.checkFinalResult();

    this.refreshIntervalId = setInterval(() => {
      this.getItems();
    }, 1000 * 60);
  },
  unmounted() {
    this.$store.dispatch('SOCKET_DIS_CONNECT_PENDING', this);
    clearInterval(this.refreshIntervalId);
  },
  methods: {
    getItems() {
      const { allPendingInfo } = this;
      if (!allPendingInfo || allPendingInfo.length === 0 || allPendingInfo?.[0].type) {
        this.items = [];
        return;
      }
      this.items = allPendingInfo.map((pendingInfo, inx) => (this.getOneItem(pendingInfo, inx))).filter((e) => this.filterShowDelete === 'accepted' || !e.isHiddenRQ);
    },
    getOneItem(obj, inx) {
      const commission = obj?.bookTransaction?.hotel?.commission - 5;
      return {
        tag: obj.operatorTag,
        pnr: obj.pnr,
        agencyName: this.allAgentList.find((agent) => agent.agt_Code === obj.odyAgentCode)?.agt_Name || '',
        hotelOrderNum: obj.bookTransaction?.hotelOrderNum,
        destination: `${obj.destinationCode} - ${obj.destination}`,
        status: obj.bookTransaction?.status?.code || obj.bookTransaction?.status?.description,
        client: obj.bookTransaction?.paxes[0]?.fullName,
        clerkName: obj.clerkName,
        bookDate: (obj.bookDate) === null ? null : new Date(obj.bookDate).toString().substring(0, 16),
        checkinDate: obj.checkinDate,
        hotelName: obj.bookTransaction?.hotel?.hotelName,
        roomKind: `${obj.rooms[0]?.roomCompositionTypeCode}(${obj.rooms[0].adults + obj.rooms[0].children})`,
        rooms: obj.rooms,
        roomsNumber: obj.rooms.length,
        basisCode: obj.rooms[0]?.basisCode,
        // roomClassName: obj.rooms[0]?.roomClassName,
        adult: obj.adults,
        children: obj.children,
        infants: obj.infants,
        finalPrice: Math.round(obj.bookTransaction.finalPrice / (1 - commission / 100)),
        netPrice: obj.bookTransaction?.finalPrice,
        totalPaid: Math.round(obj.rooms.reduce((total, r) => total + r.totalOriginal, 0)),
        paidToFc: obj.bookTransaction?.confirmedPnrState?.payedAmountToFc || 0,
        transId: obj.bookTransaction?.guid,
        diffMinute: this.delayMinute - dayjs().diff(obj.bookDate, 'm'),
        isSucceeded: obj.bookTransaction.status?.code === 10 || obj.bookTransaction.status?.code === 11 || false, // obj.bookTransaction?.hotelOrderNum !== null,
        isHiddenRQ: obj.operatorFlags?.hiddenRQ || false,
        isAtlantis: obj.hotelSource === 0,
        index: inx,
      };
    },
    async atlantisAction(bookingTransId, inx) {
      this.currentIndex = inx;
      await this.$store.dispatch('POST_ATLANTIS_HOTEL_EXTERNAL_BOOK', { bookingTransId, body: {} });
    },
    async firstAction(bookingTransId, inx) {
      this.currentIndex = inx;
      await this.$store.dispatch('POST_FINALIZE_PENDING_EXTERNAL_BOOK', { bookingTransId, body: {} });
    },
    async secondAction(bookingTransId, inx) {
      await this.$store.dispatch('FETCH_NEW_PRICE_INFO', { bookingTransId });
      const { newTokenPriceInfo } = this;

      if (newTokenPriceInfo.error) {
        this.resultState = 'error';

        this.resultTitle = 'Error';
        this.resultMessage = newTokenPriceInfo.error.message;
        this.$bvModal.show('resultModal');

        return;
      }
      const newData = newTokenPriceInfo.data;
      const arrayData = Object.keys(newData).map((key) => newData[key]);
      const totalPrice = Math.round(arrayData.reduce((total, num) => (total + num.total), 0));
      const body = {
        roomRate: newData,
        totalPrice,
      };
      this.$store.commit('SET_EXTERNAL_BOOKING_INFO', { bookingTransId, body });
      this.gotoBook(inx, arrayData, totalPrice);
    },
    async thirdAction(bookingTransId, hide) {
      // eslint-disable-next-line no-restricted-globals, no-alert
      if (!confirm(`Are you sure to ${hide ? 'delete' : 'restore'} this row?`)) return;

      const response = await this.$store.dispatch('SET_RQ_HIDDEN', { bookingTransId, hide });
      if (response.error) {
        alert(response.error.message);
      } else {
        this.getItems();
      }
    },
    async gotoBook(index, freshInfo, totalPrice) {
      const supplierCodeList = process.env.VUE_APP_SUPPLIER_LIST.split(',');
      const bookingInfo = this.allPendingInfo[index];
      const rooms = [];
      const commission = bookingInfo.bookTransaction.hotel.commission - 5;
      const netPrice = Math.round(totalPrice * (1 - commission / 100));

      bookingInfo.rooms.forEach((room, inx) => {
        const roomData = {
          searchGuid: room.searchGuid,
          bookingToken: freshInfo[inx].token,
          totalAfterDiscount: freshInfo[inx].totalAfterDiscount,
          discountPercent: room.discountPercent,
          totalOriginal: freshInfo[inx].totalInOrgCur,
          currencyCode: room.currencyCode,
          roomCode: room.roomCode,
          basisCode: room.basisCode,
          priceListCode: room.priceListCode,
          adults: Number(room.adults),
          children: Number(room.children),
          infant: Number(room.infant),
          roomOccupancyCode: room.roomOccupancyCode,
          roomOccupString: room.roomOccupString,
          roomClassName: room.roomClassName,
          roomBasisName: room.roomBasisName,
          category: room.category,
          nett: room.nett,
          paxIds: room.paxIds,
        };
        rooms.push(roomData);
      });

      const product = {
        agentScreenResolultion: this.device,
        grandTotalAfterDiscount: totalPrice,
        netPrice,
        priceBeforeCoupon: netPrice,
        couponCode: '',
        currencyCode: bookingInfo.bookTransaction.currency,
        hotel: {
          source: bookingInfo.bookTransaction.hotel.source,
          hotelCode: bookingInfo.bookTransaction.hotel.hotelCode,
          cityName: bookingInfo.bookTransaction.hotel.cityName,
          checkinDate: bookingInfo.bookTransaction.hotel.checkinDate,
          checkoutDate: bookingInfo.bookTransaction.hotel.checkoutDate,
          remarkInVoucher: bookingInfo.bookTransaction.hotel.remarkInVoucher,
          hotelName: bookingInfo.bookTransaction.hotel.hotelName,
          cityCode: bookingInfo.bookTransaction.hotel.cityCode,
          hotelSupplierCode: supplierCodeList[bookingInfo.bookTransaction.hotel.source],
          rooms,
        },
        paxList: bookingInfo.bookTransaction.paxes,
        payer: bookingInfo.bookTransaction.payer,
        dealId: bookingInfo.bookTransaction.dealId,
      };

      const data = {
        data: product,
        sendingStage: 'external',
      };

      const currency = (bookingInfo.currencyCode === 'IS' || bookingInfo.currencyCode === 'NIS') ? '₪' : '$';
      // const finalPrice = Math.round(bookingInfo.bookTransaction.finalPrice / (1 - commission / 100));
      const h = this.$createElement;
      const titleVNode = h('div', { class: ['text-center'] }, this.$t('pending-info.continue-modal-title'));
      const messageVNode = h('div', { class: ['price-message-body'] }, [
        h('p', { class: ['text-right', 'my-0'] }, [h('strong', `${this.$t('search-result.hotel')}: `), bookingInfo.bookTransaction.hotel.hotelName]),
        h('p', { class: ['text-right', 'my-0'] }, [h('strong', `${this.$t('search-result.city')}: `), bookingInfo.bookTransaction.hotel.cityName]),
        // h('p', { class: ['text-right', 'my-0'] }, [h('strong', `${this.$t('pending-info.old-price')}: `), `${currency}${bookingInfo.bookTransaction.finalPrice}`]),
        h('p', { class: ['text-right', 'my-0'] }, [h('strong', `${this.$t('pending-info.old-price')}: `), `${currency}${totalPrice}`]),
        h('p', { class: ['text-right', 'my-0'] }, [h('strong', `${this.$t('pending-info.new-price')}: `), `${currency}${netPrice}`]),
      ]);
      this.$bvModal.msgBoxConfirm([messageVNode], {
        title: [titleVNode],
        // size: 'lg',
        // buttonSize: 'sm',
        okVariant: 'success',
        headerClass: 'p-2 border-bottom-0 price-compare',
        footerClass: 'p-2 border-top-0',
        centered: true,
        okTitle: this.$t('login.yes'),
        cancelTitle: this.$t('login.no'),
        hideHeaderClose: false,
      })
        .then((value) => {
          if (value) {
            window.localStorage.setItem('BookingDetailInfo', toBase64(JSON.stringify(data)));
            window.localStorage.setItem('production', toBase64(JSON.stringify(product)));
            window.localStorage.setItem('BookingDataID', bookingInfo.bookTransaction.guid);
            window.localStorage.setItem('retryState', 'null');
            this.$router.push({ path: `/booking/hotel?lang=${this.lang}` });
          }
        });
    },
    checkFinalResult() {
      const { finalizeInfo } = this;
      if (finalizeInfo) {
        this.resultState = finalizeInfo.paymentSucceeded ? 'success' : 'error';

        this.resultTitle = finalizeInfo.status.description;
        this.resultMessage = finalizeInfo.paymentSucceeded ? finalizeInfo.messageToVisitor : finalizeInfo.error.message;
        this.resultPNR = finalizeInfo.pnr;
        this.resultHotelOrderNumber = finalizeInfo.hotelOrderNumber;
        this.voucherLink = finalizeInfo.pdfUrlHotelVoucher;
        this.$bvModal.show('resultModal');

        if (this.currentIndex !== null) {
          this.allPendingInfo[this.currentIndex].bookTransaction.hotelOrderNum = this.finalizeInfo.hotelOrderNumber;
          this.allPendingInfo[this.currentIndex].bookTransaction.status = this.finalizeInfo.status;
          this.getItems();
          this.currentIndex = null;
        }

        this.$store.commit('SET_FINALIZE_INFO', null);
      }
    },
    checkAtlantisFinalResult() {
      const { atlantisFinalizeInfo } = this;
      if (atlantisFinalizeInfo) {
        if (atlantisFinalizeInfo.error) {
          this.resultState = 'error';
          this.resultTitle = `The error code is: ${atlantisFinalizeInfo.error.code}`;
          this.resultMessage = atlantisFinalizeInfo.error.description;
        }
        this.resultPNR = '';
        this.resultHotelOrderNumber = '';
        this.voucherLink = atlantisFinalizeInfo.pdfUrlHotelVoucher;
        this.$bvModal.show('resultModal');

        if (this.currentIndex !== null) {
          this.allPendingInfo[this.currentIndex].bookTransaction.status.code = 10;
          this.getItems();
          this.currentIndex = null;
        }
        this.$store.commit('SET_ATLANTIS_FINALIZE_INFO', null);
      }
    },
    showFilterModal() {
      this.$bvModal.show('filterModal');
    },
    applyFilter() {
      this.$store.commit('FILTER_PENDING_BOOKING_INFO', { pnr: this.filterPnr, agent: this.filterAgent, tag: this.filterTag, clerk: this.filterClerkName });
    },
    async changeTag(value, transId) {
      await this.$store.dispatch('SET_TAG_OPTION', { bookingTransId: transId, tag: value });
      this.getItems();
    },
    rowColorClassByTag(item, type) {
      if (!item || type !== 'row') return '';
      if (item.tag === 'notActive') return 'table-warning';
      if (item.tag === 'noPaid') return 'table-success';
      if (item.tag === 'paidPartly') return 'table-primary';
      if (item.tag === 'canceled') return 'table-danger';
      return '';
    },
    downloadTickets(pURL, pName) {
      this.$bvToast.toast(this.$t('booking.waiting-downloading'), {
        title: this.$t('booking.downloading'),
        autoHideDelay: 30 * 1000,
        appendToast: true,
        variant: 'info',
        noCloseButton: true,
        bodyClass: this.lang === 'he' ? 'rtl' : 'ltr',
      });
      const fileName = `${pName}.pdf`;
      if (!window.ActiveXObject) {
        const save = document.createElement('a');
        save.href = pURL;
        save.target = '_blank';
        save.download = fileName;
        if (navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search('Chrome') < 0) {
          document.location = save.href;
          // window event not working here
        } else {
          const evt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: false,
          });
          save.dispatchEvent(evt);
          (window.URL || window.webkitURL).revokeObjectURL(save.href);
        }
      } else if (!!window.ActiveXObject && document.execCommand) {
        const _window = window.open(pURL, '_blank');
        _window.document.close();
        _window.document.execCommand('SaveAs', true, fileName);
        _window.close();
      }
    },
  },
};
</script>

<style>
  .price-compare.modal-header .close {
    margin: -1rem auto -1rem -1rem;
  }
  .price-message-body {
    font-size: 1.2rem;
  }
</style>

<style scoped>
.please_mall {
  width: 100%;
  text-align: center;
}
.please_mall .icon_box {
  width: 88px;
  height: 82px;
  border: 1px solid #0763ab;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto 15px;
}
.please_mall h5 {
  text-transform: uppercase;
  color: #000;
  margin: 0 0;
  font-size: 16px;
}

.external-price-container {
  max-width: 100%;
  margin: 1rem;
}
</style>
